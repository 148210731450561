const BINDING = 'bindingDate';
const DD = 'dueDiligenceDuration';
const EM = 'emDuration';
const FINANCING = 'financingDuration';
const APPRAISAL = 'appraisalDuration';
const CLOSING = 'closingDate';

export const TIMELINE = {
  BINDING,
  DD,
  EM,
  FINANCING,
  APPRAISAL,
  CLOSING,
};

export const SUGGESTIONS = [BINDING, FINANCING, APPRAISAL, CLOSING];

export const TITLES = {
  [BINDING]: 'Binding Date',
  [DD]: 'Due Diligence Deadline',
  [EM]: 'Earnest Money Deadline',
  [FINANCING]: 'Financing Contigency',
  [APPRAISAL]: 'Appraisal Contigency',
  [CLOSING]: 'Closing Date',
};

export const COLORS = {
  [BINDING]: 'blue',
  [DD]: 'pink',
  [EM]: 'purple',
  [FINANCING]: 'green',
  [APPRAISAL]: 'orange',
  [CLOSING]: 'red',
};

import { Center, Spinner } from '@chakra-ui/react';

function Loading() {
  return (
    <Center w="100vw" h="100vh">
      <Spinner w="3em" h="3em" />
    </Center>
  );
}

export default Loading;

import PropTypes from 'prop-types';
import { useState } from 'react';
import { Stack, Box, IconButton, Text, Input } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import TaskList from '../../Tasks/TaskList';
import { useTasks, createTask, tasksCollection } from '../../resources/tasks';
import './Tasks.css';

function CreateTask({ parent }) {
  const [newTaskText, setNewTaskText] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await createTask(tasksCollection, {
      text: newTaskText,
      parent: parent.ref,
      parentDisplayName: parent.data().name,
    });
    setNewTaskText('');
  };
  return (
    <Stack isInline as="form" onSubmit={handleSubmit}>
      <Input
        size="sm"
        value={newTaskText}
        onChange={(event) => setNewTaskText(event.target.value)}
        p={4}
        placeholder="Enter a new task..."
      />
      <IconButton
        size="sm"
        type="submit"
        colorScheme="teal"
        disabled={newTaskText === ''}
        icon={<AddIcon />}
      />
    </Stack>
  );
}

CreateTask.propTypes = {
  parent: PropTypes.object.isRequired,
};

function TransactionTasks({ transaction }) {
  const [tasks, isLoading, error] = useTasks(
    tasksCollection
      .where('parent', '==', transaction.ref)
      .orderBy('startDate', 'asc')
      .orderBy('createdAt', 'desc')
  );

  if (error) {
    throw new Error('Unable to load transaction tasks' + error);
  }

  return (
    <>
      <Box p={2}>
        <CreateTask parent={transaction} />
      </Box>
      {!isLoading && (
        <Box className="transaction-tasks">
          {tasks.size > 0 ? (
            <TaskList tasks={tasks.docs} />
          ) : (
            <Text py={4} textAlign="center">
              No tasks.
            </Text>
          )}
        </Box>
      )}
    </>
  );
}

TransactionTasks.propTypes = {
  transaction: PropTypes.shape({
    ref: PropTypes.object,
  }).isRequired,
};

export default TransactionTasks;

import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import ContentEditable from 'react-contenteditable';
import { useTransactionMutation } from '../../resources/transactions';

function Notes({ transaction }) {
  const { notes } = transaction.data();
  const [value, setValue] = useState(notes || '');
  const [updateTransaction] = useTransactionMutation(transaction);

  return (
    <Box
      as={ContentEditable}
      borderRadius="md"
      borderWidth="1px"
      minH="180px"
      layerStyle="panel"
      p={2}
      html={value}
      placeholder="Add notes..."
      overflow="auto"
      resize="vertical"
      _hover={{
        borderColor: 'teal.500',
      }}
      _focus={{
        boxShadow: 'none',
        outline: 'none',
        borderColor: 'teal.500',
      }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onBlur={(event) => {
        if (notes !== event.target.innerHTML) {
          updateTransaction({ notes: event.target.innerHTML });
        }
      }}
    />
  );
}

Notes.propTypes = {
  transaction: PropTypes.shape({
    data: PropTypes.func,
  }).isRequired,
};

export default Notes;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { Route, Switch } from 'react-router';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router';
import ErrorBoundary from '@common/ErrorBoundary';
import Panel, { PanelHeader, PanelNav, PanelBody } from '@common/Panel';
import { ErrorScreen } from '@common/ErrorBoundary';
import { useMyTasks, useTasksOnce } from '../resources/tasks';
import TaskList from './TaskList';
import NewTask from './NewTask';
import Filters, { useFilters, filterCollection } from './Filters';
import MyTasks from './MyTasks';
import { useCurrentUser } from 'resources/auth';

function AllTasksView({ filters, limit = 50 }) {
  const [tasks = {}, isLoading, error] = useTasksOnce(
    filterCollection(filters, limit)
  );
  const isReady = !isLoading && !!tasks.metadata && !tasks.metadata.fromCache;

  return (
    <>
      <Box layer="canvas" borderBottomWidth="1px" p={4}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Heading as="h3" size="xs" fontWeight="semibold">
            Showing {tasks.size}
            {tasks.size === 50 && '+'} tasks
          </Heading>
        )}
      </Box>
      <Box layerStyle="panel">
        {error && <ErrorScreen error={error} />}
        {isReady && !tasks.empty && <TaskList tasks={tasks.docs} />}
        {isReady && tasks.empty && (
          <Text py={4} textAlign="center" color="gray.600">
            No tasks.
          </Text>
        )}
      </Box>
    </>
  );
}

AllTasksView.propTypes = {
  filters: PropTypes.object,
  limit: PropTypes.number,
};

function MyTasksView() {
  const [currentUser] = useCurrentUser();
  const tasks = useMyTasks(currentUser);
  return tasks.isLoading ? <Spinner /> : <MyTasks tasks={tasks} />;
}

const tabMap = ['/tasks', '/tasks/all'];

function Tasks() {
  const [filters, updateFilters] = useFilters();
  const [isCreating, setIsCreating] = useState(false);
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <Panel>
        <PanelHeader
          actions={
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={() => setIsCreating(true)}
              leftIcon={<AddIcon />}
            >
              New Task
            </Button>
          }
        >
          <Switch>
            <Route exact path="/tasks">
              My Tasks
            </Route>
            <Route path="/tasks/all">Tasks</Route>
          </Switch>
        </PanelHeader>
        <PanelBody p={0} layerStyle="canvas">
          <Tabs
            size="sm"
            colorScheme="teal"
            variant="solid-rounded"
            onChange={(index) => history.push(tabMap[index])}
            index={tabMap.findIndex((tab) => location.pathname === tab)}
          >
            <PanelNav>
              <Flex justifyContent="space-between" alignItems="center" pr={4}>
                <TabList>
                  <Tab>My Tasks</Tab>
                  <Tab>All Tasks</Tab>
                </TabList>
                <Box opacity={location.pathname === '/tasks/all' ? 1 : 0}>
                  <Filters filters={filters} onFilterToggle={updateFilters} />
                </Box>
              </Flex>
            </PanelNav>
            <TabPanels>
              <TabPanel p={0}>
                <MyTasksView />
              </TabPanel>
              <TabPanel p={0}>
                <AllTasksView filters={filters} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PanelBody>
      </Panel>
      <NewTask isOpen={isCreating} onClose={() => setIsCreating(false)} />
    </>
  );
}

function TasksWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <Tasks {...props} />
    </ErrorBoundary>
  );
}

export default TasksWithErrorBoundary;

import { createContext, useContext, useEffect, useState } from 'react';
import { functions } from './firebase';

const getCollaborators = functions.httpsCallable('getCollaborators');

const CollaboratorsContext = createContext([]);

function fetchCollaborators() {
  const cached = window.localStorage.getItem('collaborators');
  if (cached) {
    return Promise.resolve(JSON.parse(cached));
  }

  return getCollaborators().then((result) => {
    window.localStorage.setItem('collaborators', JSON.stringify(result));
    return result;
  });
}

export function useCollaborators() {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    fetchCollaborators()
      .then((result) => {
        setIsLoading(false);
        setValue(result.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, []);

  return [value, isLoading, error];
}

export function useCollaboratorsList() {
  return useContext(CollaboratorsContext);
}

export function getUidByDisplayName(collaborators, displayName) {
  return collaborators.find((c) => c.displayName.startsWith(displayName)).uid;
}

export const CollaboratorsProvider = CollaboratorsContext.Provider;

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Heading,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useCurrentUser } from '../resources/auth';
import NewTask from './NewTask';
import MyTasks from './MyTasks';

function TasksDrawer({ tasks, isOpen, onClose }) {
  const [isCreating, setIsCreating] = useState(false);
  const [currentUser] = useCurrentUser();

  return (
    <>
      <Drawer
        scrollBehavior="inside"
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading size="md" level={2}>
              My Tasks
            </Heading>
            <ButtonGroup>
              <Button
                colorScheme="teal"
                size="sm"
                leftIcon={<AddIcon />}
                onClick={() => setIsCreating(true)}
              >
                New
              </Button>
            </ButtonGroup>
          </DrawerHeader>
          <DrawerBody overflowY="scroll" px={0} py={4} layerStyle="canvas">
            <MyTasks tasks={tasks} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <NewTask
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
        initialValues={{
          assignee: currentUser.uid,
        }}
      />
    </>
  );
}

TasksDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tasks: PropTypes.object.isRequired,
};

export default TasksDrawer;

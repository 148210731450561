import PropTypes from 'prop-types';
import {
  Box,
  Link,
  Center,
  Text,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { formatDate } from './utils/date';
import { useTransactions } from '../resources/transactions';
import { useAgent } from '../resources/agents';
import { Status } from './TransactionStatus';

function SkeletonRow({ columns }) {
  return (
    <Tr>
      {Array(columns)
        .fill(null)
        .map((_, index) => (
          <Td key={index}>
            <Skeleton height="12px" />
          </Td>
        ))}
    </Tr>
  );
}

SkeletonRow.propTypes = {
  columns: PropTypes.number.isRequired,
};

function AgentCell({ agentPath }) {
  const [agent, isLoading] = useAgent(agentPath);
  const agentData = isLoading ? {} : agent.data();

  return (
    <Td>
      {isLoading ? (
        <Skeleton height="12px" />
      ) : (
        <Link as={RouterLink} to={`/agents/${agent.id}`}>
          {agentData.name}
        </Link>
      )}
    </Td>
  );
}

AgentCell.propTypes = {
  agentPath: PropTypes.string.isRequired,
};

function TransactionsTable({
  emptyState = 'No transactions found.',
  collection,
  columns,
}) {
  const [transactions, isLoading, error] = useTransactions(collection);
  const maxColumns = useBreakpointValue({ base: 2, md: 99 });

  if (error) {
    console.error(error);
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  if (!isLoading && transactions.size === 0) {
    return <Center p={4}>{emptyState}</Center>;
  }

  const headers = Object.values(columns).slice(0, maxColumns);
  const cols = Object.keys(columns).slice(0, maxColumns);
  return (
    <Box overflowX="auto">
      <Table fontSize={{ base: 'sm', md: 'sm' }}>
        <Thead>
          <Tr>
            {headers.map((header) => (
              <Th position="sticky" top={0} key={header}>
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <>
              <SkeletonRow columns={cols.length} />
              <SkeletonRow columns={cols.length} />
              <SkeletonRow columns={cols.length} />
              <SkeletonRow columns={cols.length} />
              <SkeletonRow columns={cols.length} />
            </>
          ) : (
            transactions.docs.map((doc) => {
              const transaction = doc.data();
              return (
                <Tr layerStyle="highlighted" key={doc.id}>
                  {cols.map((column) => {
                    if (column === 'agent') {
                      return (
                        <AgentCell
                          key="agent"
                          agentPath={transaction.agent.path}
                        />
                      );
                    }

                    let data = transaction[column];
                    if (
                      column === 'createdAt' ||
                      column === 'updatedAt' ||
                      column === 'closingDate'
                    ) {
                      return (
                        <Td position="sticky" top={0} key={column}>
                          {transaction[column]
                            ? formatDate(transaction[column], 'M/d')
                            : ''}
                        </Td>
                      );
                    }

                    if (column === 'status') {
                      return (
                        <Td key={column}>
                          <Status type={data} />
                        </Td>
                      );
                    }

                    if (column === 'name') {
                      return (
                        <Td key={column}>
                          <Text fontWeight="semibold">
                            <Link as={RouterLink} to={'/' + doc.ref.path}>
                              {transaction[column]}
                            </Link>
                          </Text>
                        </Td>
                      );
                    }
                    return <Td key={column}>{data}</Td>;
                  })}
                </Tr>
              );
            })
          )}
        </Tbody>
      </Table>
    </Box>
  );
}

TransactionsTable.propTypes = {
  collection: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  emptyState: PropTypes.string,
};

export default TransactionsTable;

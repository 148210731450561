import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  InputGroup,
  FormErrorMessage,
  Input,
  Spinner,
  Skeleton,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import FormField from '@common/FormField';
import useDraftValue from '@common/useDraftValue';
import { useTransactionMutation } from '../../../resources/transactions';

function Money({ label, name, document, ...props }) {
  const [hasFocus, setHasFocus] = useState(false);
  const data = document.exists ? document.data() : {};
  const key = Array.isArray(name) ? name.join('.') : name;
  let value = data[name] !== undefined && data[name] !== null ? data[name] : '';
  // Break up nested fields (like exhibits)
  if (Array.isArray(name)) {
    value =
      data[name[0]] !== undefined && data[name[0]] !== null
        ? data[name[0]][name[1]]
        : '';
  }

  const [update, isUpdating, error] = useTransactionMutation(document);
  const [draftValue, setDraftValue] = useDraftValue(value, isUpdating);

  return (
    <FormField label={label} isInvalid={!!error}>
      <Skeleton isLoaded={document.exists}>
        <InputGroup size="sm" {...props} maxW="140px">
          <InputLeftElement w="2rem" pointerEvents="none">
            $
          </InputLeftElement>

          <Input
            inputMode="tel"
            value={
              hasFocus
                ? draftValue
                : draftValue
                ? draftValue.toLocaleString()
                : ''
            }
            onChange={(event) => {
              const num = Number(event.target.value);
              setDraftValue(isNaN(num) ? '' : num);
            }}
            onFocus={() => setHasFocus(true)}
            onBlur={() => {
              setHasFocus(false);
              if (draftValue !== data[name]) {
                update({ [key]: draftValue });
              }
            }}
            w="400px"
            {...props}
          />
          <InputRightElement>{isUpdating ? <Spinner /> : ''}</InputRightElement>
        </InputGroup>
      </Skeleton>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormField>
  );
}

Money.propTypes = {
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Money;

import {
  useCollection,
  useDocument,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import {
  DEFAULT_CONTRACT_FEE,
  DEFAULT_TERMINATION_FEE,
} from '../constants/fees';
import { firestore } from './firebase';

export const agentsCollection = firestore.collection('agents');

export function useAgents(collection) {
  const [data, isLoading, error] = useCollection(collection);
  return [data || {}, isLoading, error];
}

export function useAgent(path) {
  const ref = firestore.doc(path);
  return useDocument(ref);
}

export function useAgentDataOnce(path) {
  const ref = firestore.doc(path);
  return useDocumentDataOnce(ref);
}

function createIdFromName(name) {
  return name
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '_') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function createAgent(data) {
  const id = createIdFromName(data.name);
  return agentsCollection
    .doc(id)
    .set({
      defaultFee: DEFAULT_CONTRACT_FEE,
      defaultTerminationFee: DEFAULT_TERMINATION_FEE,
      ...data,
    })
    .then(() => agentsCollection.doc(id));
}

export function updateAgent(agent, data) {
  return agent.ref.update(data);
}

export function getInitialAgentState() {
  return {
    name: '',
    email: '',
    license: '',
    defaultFee: DEFAULT_CONTRACT_FEE,
    defaultTerminationFee: DEFAULT_TERMINATION_FEE,
  };
}

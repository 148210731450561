import PropTypes from 'prop-types';
import { Fieldset } from '@common/FormField';
import { Text } from '../View/Fields';

function Exhibits({ transaction }) {
  return (
    <Fieldset legend="Exhibits">
      <Text label="Exhibit A" name={['exhibits', 'A']} document={transaction} />
      <Text label="Exhibit B" name={['exhibits', 'B']} document={transaction} />
      <Text label="Exhibit C" name={['exhibits', 'C']} document={transaction} />
      <Text label="Exhibit D" name={['exhibits', 'D']} document={transaction} />
      <Text label="Exhibit E" name={['exhibits', 'E']} document={transaction} />
      <Text label="Exhibit F" name={['exhibits', 'F']} document={transaction} />
      <Text label="Exhibit G" name={['exhibits', 'G']} document={transaction} />
    </Fieldset>
  );
}

Exhibits.propTypes = {
  transaction: PropTypes.object,
};

export default Exhibits;

import PropTypes from 'prop-types';
import {
  Checkbox as ChakraCheckbox,
  FormErrorMessage,
  Spinner,
} from '@chakra-ui/react';
import { useTransactionMutation } from '../../../resources/transactions';

function Checkbox({ name, document, ...props }) {
  const [update, isUpdating, error] = useTransactionMutation(document);
  const data = document.exists ? document.data() : {};
  return (
    <>
      <ChakraCheckbox
        isChecked={data[name]}
        onChange={(event) => {
          update({ [name]: event.target.checked });
        }}
        {...props}
      />
      {isUpdating && <Spinner ml={2} h="1em" w="1em" />}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </>
  );
}

Checkbox.propTypes = {
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export default Checkbox;

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Text,
  Input,
  Button,
  Divider,
  Box,
  Circle,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Calendar } from 'react-calendar';
import { isValid } from 'date-fns';
import { TODAY, normalizeTimezone } from '@common/utils/date';
import { COLORS, TITLES } from '../../constants/timelineDates';
import getDateSuggestions from './getDateSuggestions';
import './DateInputPopup.css';

function SuggestedDate(props) {
  return (
    <Button
      {...props}
      variant="soft"
      justifyContent="space-between"
      size="sm"
      _highlighted={{
        bg: 'gray.600',
        color: 'white',
      }}
    />
  );
}

function formatDate(date) {
  if (!isValid(date)) {
    return '';
  }

  if (date.getFullYear() === TODAY.getFullYear()) {
    return format(normalizeTimezone(date), 'M/d');
  }

  return format(normalizeTimezone(date), 'M/d/yyyy');
}

function DateInputPopup({ baseDates = {}, value, onChange, inputRef }) {
  const [input, setInput] = useState(() => formatDate(value));

  const allSuggestions = getDateSuggestions(baseDates, input);
  const topSuggestion = allSuggestions[0];
  const [calendarDate, setCalendarDate] = useState();

  const handleDateSelect = (date) => {
    onChange(date);
    setInput(formatDate(date));
    setCalendarDate();
  };

  return (
    <Stack divider={<Divider />}>
      <Input
        ref={inputRef}
        size="sm"
        autoFocus
        placeholder=""
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            if (topSuggestion) {
              handleDateSelect(topSuggestion.value);
            } else if (input === '') {
              handleDateSelect();
            }
          }
        }}
      />
      <Stack>
        <Stack>
          {allSuggestions.length > 0 ? (
            allSuggestions.map((suggestedDate, index) => (
              <SuggestedDate
                data-highlighted={index === 0 ? 'true' : undefined}
                key={suggestedDate.label}
                colorScheme={COLORS[suggestedDate.base]}
                onClick={() => {
                  handleDateSelect(suggestedDate.value);
                }}
                variant="soft"
                justifyContent="space-between"
                size="sm"
              >
                <span>
                  {suggestedDate.label}
                  {suggestedDate.value.getFullYear() !== TODAY.getFullYear() &&
                    `, ${suggestedDate.value.getFullYear()}`}
                </span>
                <small>{format(suggestedDate.value, 'E M/d')}</small>
              </SuggestedDate>
            ))
          ) : (
            <Text textAlign="center" color="gray.500">
              No results
            </Text>
          )}
        </Stack>

        <Box>
          <Calendar
            // locale={enUS}
            activeStartDate={
              calendarDate
                ? calendarDate
                : topSuggestion
                ? topSuggestion.value
                : TODAY
            }
            value={
              calendarDate
                ? calendarDate
                : topSuggestion
                ? topSuggestion.value
                : TODAY
            }
            locale="en-US"
            showFixedNumberOfWeeks={true}
            minDetail="year"
            nextLabel={<ChevronRightIcon />}
            next2Label={null}
            prevLabel={<ChevronLeftIcon />}
            prev2Label={null}
            onActiveStartDateChange={({ activeStartDate }) => {
              setCalendarDate(activeStartDate);
            }}
            onChange={(date) => {
              handleDateSelect(date);
            }}
            tileContent={({ date, view }) => {
              // Add class to tiles in month view only
              if (view === 'month') {
                const events = Object.keys(baseDates).filter((key) => {
                  return isSameDay(baseDates[key], date);
                });

                if (events.length === 0) {
                  return null;
                }

                return (
                  <Tooltip
                    label={events.map((event) => (
                      <p key={event}>{TITLES[event]}</p>
                    ))}
                  >
                    <Flex
                      position="absolute"
                      w="100%"
                      h="100%"
                      top={0}
                      bottom={0}
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      {events.map((key) => {
                        return (
                          <Circle
                            w={2}
                            h={2}
                            bg={`${COLORS[key]}.200`}
                            key={key}
                          />
                        );
                      })}
                    </Flex>
                  </Tooltip>
                );
              }
            }}
          />
        </Box>
      </Stack>

      {value && (
        <Button size="sm" onClick={() => handleDateSelect()}>
          Clear
        </Button>
      )}
    </Stack>
  );
}

DateInputPopup.propTypes = {
  baseDates: PropTypes.object,
  inputRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

export default DateInputPopup;

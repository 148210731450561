import PropTypes from 'prop-types';
import {
  Heading,
  Button,
  Alert,
  AlertIcon,
  Text,
  Stack,
  Flex,
  Box,
} from '@chakra-ui/react';
import TaskList from './TaskList';
import { archiveTasks } from '../resources/tasks';

function TaskListContainer({ tasks, title }) {
  const completedTasks = tasks.docs.filter((task) => task.data().isComplete);
  return (
    <Box>
      <Flex
        p={4}
        justifyContent="space-between"
        borderBottomWidth="1px"
        alignItems="center"
      >
        <Heading as="h4" size="sm">
          {title}
        </Heading>
        <Button
          disabled={completedTasks.length === 0}
          _disabled={{
            opacity: 0,
          }}
          size="xs"
          onClick={() => archiveTasks(completedTasks)}
        >
          Archive Completed
        </Button>
      </Flex>
      <Box layerStyle="panel">
        {tasks.empty ? (
          <Text py={4} textAlign="center" textStyle="secondary">
            No tasks.
          </Text>
        ) : (
          <TaskList tasks={tasks.docs} />
        )}
      </Box>
    </Box>
  );
}

TaskListContainer.propTypes = {
  tasks: PropTypes.object,
  title: PropTypes.node,
};

function MyTasks({ tasks: { today, unscheduled, upcoming, error } }) {
  return (
    <Stack spacing={8} className="my-tasks">
      {error && (
        <Alert>
          <AlertIcon />
          Unable to load tasks.
          {error}
        </Alert>
      )}
      <TaskListContainer title="Today" tasks={today} />
      <TaskListContainer title="Unscheduled" tasks={unscheduled} />
      <TaskListContainer title="Upcoming" tasks={upcoming} />
    </Stack>
  );
}

MyTasks.propTypes = {
  tasks: PropTypes.shape({
    error: PropTypes.string,
    today: PropTypes.object.isRequired,
    unscheduled: PropTypes.object.isRequired,
    upcoming: PropTypes.object.isRequired,
  }).isRequired,
};

export default MyTasks;

import PropTypes from 'prop-types';
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Select from 'react-select';

function CustomInput(props) {
  const {
    cx,
    getStyles,
    innerRef,
    selectProps,
    theme,
    isHidden,
    options,
    selectOption,
    setValue,
    isRtl,
    isMulti,
    hasValue,
    getValue,
    clearValue,
    ...rest
  } = props;
  // const [selected] = getValue();
  // let value = rest.value;
  // if (value.length === 0 && selected) {
  //   value = selected.label;
  // }
  const value =
    !selectProps.menuIsOpen && hasValue ? selectProps.value.label : rest.value;

  return (
    <InputGroup>
      <Input {...rest} value={value} placeholder={selectProps.placeholder} />
      <InputRightElement>
        <ChevronDownIcon />
      </InputRightElement>
    </InputGroup>
  );
}

// Proptypes for custominput are just to omit their passthrough and not important
CustomInput.propTypes = {
  clearValue: PropTypes.any,
  cx: PropTypes.any,
  getStyles: PropTypes.any,
  getValue: PropTypes.any,
  hasValue: PropTypes.any,
  innerRef: PropTypes.any,
  isHidden: PropTypes.any,
  isMulti: PropTypes.any,
  isRtl: PropTypes.any,
  options: PropTypes.any,
  selectOption: PropTypes.any,
  selectProps: PropTypes.any,
  setValue: PropTypes.any,
  theme: PropTypes.any,
};

function Autocomplete(props) {
  return (
    <Select
      isSearchable
      components={{
        Input: CustomInput,
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        Placeholder: () => null,
      }}
      styles={{
        valueContainer: () => ({
          padding: 0,
        }),
        singleValue: (existing) => ({
          ...existing,
          display: 'none',
        }),
        control: () => ({
          border: 0,
          padding: 0,
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected
            ? 'var(--chakra-colors-white)'
            : 'var(--chakra-colors-gray-600)',
        }),
      }}
      {...props}
    />
  );
}

export default Autocomplete;

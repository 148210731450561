import PropTypes from 'prop-types';
import { Badge, Circle } from '@chakra-ui/react';
import {
  PAYMENT_STATUSES,
  CONTRACT_STATUSES,
} from '../constants/transactionStatuses';

export const STATUSES = {
  [CONTRACT_STATUSES.ACTIVE]: {
    title: 'Active',
    color: 'cyan',
  },
  [CONTRACT_STATUSES.CLOSED]: {
    title: 'Closed',
    color: 'purple',
  },
  [CONTRACT_STATUSES.TERMINATED]: {
    title: 'Terminated',
    color: 'red',
  },
  [PAYMENT_STATUSES.PENDING]: {
    title: 'Pending',
    color: 'orange',
  },
  [PAYMENT_STATUSES.PAID]: {
    title: 'Paid',
    color: 'green',
  },
};

export function Status({ variant, type, ...props }) {
  const { title, color } = STATUSES[type];

  if (variant === 'bullet') {
    return <Circle size=".6em" bg={`${color}.400`} />;
  }
  return (
    <Badge colorScheme={color} {...props}>
      {title}
    </Badge>
  );
}

Status.propTypes = {
  type: PropTypes.oneOf(Object.values(CONTRACT_STATUSES)).isRequired,
  variant: PropTypes.string,
};

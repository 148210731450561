import PropTypes from 'prop-types';
import {
  Select as ChakraSelect,
  FormErrorMessage,
  Spinner,
  Skeleton,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import FormField from '@common/FormField';
import { useBlockUnload } from '@common/useDraftValue';
import { useTransactionMutation } from '../../../resources/transactions';

function Select({ label, name, document, ...props }) {
  const data = document.exists ? document.data() : {};
  const [update, isUpdating, error] = useTransactionMutation(document);
  useBlockUnload(isUpdating);

  return (
    <FormField label={label}>
      <Skeleton isLoaded={document.exists}>
        <ChakraSelect
          size="sm"
          icon={isUpdating ? <Spinner /> : <ChevronDownIcon />}
          variant="outline"
          value={data[name]}
          onChange={(event) => {
            update({ [name]: event.target.value });
          }}
          {...props}
        />
      </Skeleton>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormField>
  );
}

Select.propTypes = {
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Select;

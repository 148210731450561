import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Icon,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  List,
  ListItem,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Alert,
  AlertIcon,
  Progress,
} from '@chakra-ui/react';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import { VscFolder, VscAccount, VscChecklist } from 'react-icons/vsc';
import { useSearch, searchableActions } from '../resources/search';

function QuickSearchResultIcon({ type, ...props }) {
  if (type === 'create') {
    return <AddIcon {...props} />;
  }

  if (type === 'tasks') {
    return <Icon as={VscChecklist} {...props} />;
  }

  if (type === 'agent') {
    return <Icon as={VscAccount} {...props} />;
  }

  return <Icon as={VscFolder} {...props} />;
}

QuickSearchResultIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

function QuickSearcResult({ isHighlighted, onClick, onHighlight, item }) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current && isHighlighted) {
      ref.current.scrollIntoViewIfNeeded(false);
    }
  }, [isHighlighted, ref]);

  return (
    <Stack
      as={ListItem}
      isInline
      spacing={4}
      alignItems="center"
      px={4}
      py={2}
      ref={ref}
      cursor="pointer"
      layerStyle="selected"
      onClick={onClick}
      onMouseEnter={onHighlight}
      data-highlighted={isHighlighted ? true : undefined}
    >
      <QuickSearchResultIcon type={item.type} />
      <Stack spacing={0}>
        <Text fontSize="sm">{item.name}</Text>
        {item.type === 'transaction' && (
          <Text fontSize="xs" textStyle={!isHighlighted && 'secondary'}>
            {[item.buyer, item.seller].filter(Boolean).join(' - ')}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}

QuickSearcResult.propTypes = {
  isHighlighted: PropTypes.bool,
  item: PropTypes.shape({
    address: PropTypes.string,
    buyer: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    seller: PropTypes.string,
    type: PropTypes.string,
  }),
  onClick: PropTypes.func,
  onHighlight: PropTypes.func,
};

const defaultResults = searchableActions.map((item, refIndex) => {
  return {
    item,
    refIndex,
  };
});

function QuickSearch({ isOpen, onClose }) {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const inputRef = useRef();
  const [highlightedResult, setHighlightedResult] = useState(0);
  const [search, isLoading, error] = useSearch();

  const handleClose = () => {
    setQuery('');
    onClose();
  };

  const navigateToResult = (index) => {
    handleClose();
    history.push('/' + results[index].item.path);
  };

  let results = defaultResults;
  if (search && query.length > 1) {
    results = search.search(query).slice(0, 10);
  }

  return (
    <>
      <Modal
        isLazy
        isOpen={isOpen}
        onClose={handleClose}
        initialFocusRef={inputRef}
      >
        <ModalOverlay />
        <ModalContent>
          <FormControl id="globalSearch">
            <InputGroup size="lg">
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>
              <Input
                _focus={{
                  outline: 'none',
                }}
                ref={inputRef}
                autoComplete="off"
                value={query}
                onChange={(event) => {
                  setHighlightedResult(0);
                  setQuery(event.target.value);
                }}
                placeholder="Search for agents or transactions"
                w="100%"
                onKeyDown={(event) => {
                  if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    const prevResult = highlightedResult - 1;
                    setHighlightedResult(
                      prevResult < 0 ? results.length - 1 : prevResult
                    );
                  }

                  if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    const nextResult = (highlightedResult + 1) % results.length;
                    setHighlightedResult(nextResult);
                  }

                  if (event.key === 'Enter') {
                    event.preventDefault();
                    navigateToResult(highlightedResult);
                  }
                }}
              />
            </InputGroup>
          </FormControl>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {isLoading && <Progress size="xs" isIndeterminate />}
          {results && (
            <List>
              {results.length === 0 ? (
                <Text textAlign="center" p={4}>
                  🤷🏻‍♀️ Nothing found.
                </Text>
              ) : (
                results.map((result, index) => (
                  <QuickSearcResult
                    key={result.refIndex}
                    {...result}
                    isHighlighted={index === highlightedResult}
                    onHighlight={() => setHighlightedResult(index)}
                    onClick={() => navigateToResult(index)}
                  />
                ))
              )}
            </List>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

QuickSearch.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default QuickSearch;

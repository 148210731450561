export const PAYMENT_STATUSES = {
  PENDING: 'PENDING',
  PAID: 'PAID',
};

export const CONTRACT_STATUSES = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  TERMINATED: 'TERMINATED',
};

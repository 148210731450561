import PropTypes from 'prop-types';
import {
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Spinner,
  Skeleton,
} from '@chakra-ui/react';
import FormField from '@common/FormField';
import useDraftValue from '@common/useDraftValue';
import { useTransactionMutation } from '../../../resources/transactions';

function Text({
  label,
  name,
  document,
  component: Component = Input,
  ...props
}) {
  const data = document.exists ? document.data() : {};
  const key = Array.isArray(name) ? name.join('.') : name;
  let value = data[name] || '';
  // Break up nested fields (like exhibits)
  if (Array.isArray(name)) {
    value = data[name[0]] ? data[name[0]][name[1]] : '';
  }

  const [update, isUpdating, error] = useTransactionMutation(document);
  const [draftValue, setDraftValue] = useDraftValue(value, isUpdating);

  return (
    <FormField label={label} isInvalid={!!error}>
      <Skeleton isLoaded={document.exists}>
        <InputGroup size="sm" {...props}>
          <Input
            value={draftValue || ''}
            onChange={(event) => setDraftValue(event.target.value)}
            onBlur={() => {
              if (draftValue !== value) {
                update({ [key]: draftValue });
              }
            }}
            {...props}
          />
          {isUpdating && (
            <InputRightElement>
              <Spinner />
            </InputRightElement>
          )}
        </InputGroup>
      </Skeleton>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormField>
  );
}

Text.propTypes = {
  component: PropTypes.object,
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Text;

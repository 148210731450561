import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Link,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

function TimezoneCheck({ timezone = '' }) {
  const isOpen = timezone !== 'America/New_York';
  const onRefresh = () => window.location.reload();
  return (
    <AlertDialog isOpen={isOpen}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Incorrect Timezone
          </AlertDialogHeader>

          <AlertDialogBody>
            <p>
              Your timezone must be Eastern. You can override your computer's
              timezone in Chrome by{' '}
              <Link
                target="_blank"
                href="https://dev.to/aileenr/how-to-spoof-your-timezone-in-google-chrome-48ch"
              >
                following these instructions
              </Link>
              .
            </p>
            <p>
              Once you've updated your timezone, click refresh to reload the
              app.
            </p>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="green" onClick={onRefresh} ml={3}>
              Refresh
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

TimezoneCheck.propTypes = {
  timezone: PropTypes.string,
};

export default TimezoneCheck;

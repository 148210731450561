import PropTypes from 'prop-types';
import { List, ListItem } from '@chakra-ui/react';
import Task from './Task';

function TaskList({ tasks }) {
  return (
    <List>
      {tasks.map((task) => (
        <ListItem key={task.id}>
          <Task task={task} />
        </ListItem>
      ))}
    </List>
  );
}

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
};

export default TaskList;

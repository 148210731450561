import { agentsCollection, useAgents } from '../resources/agents';
import {
  transactionsCollection,
  useTransactions,
} from '../resources/transactions';

export function useTransactionsListData() {
  const [agents, isAgentsLoading] = useAgents(
    agentsCollection.orderBy('name', 'asc')
  );
  const [allActiveTransactions, isTransactionsLoading] = useTransactions(
    transactionsCollection.where('paymentStatus', '==', 'PENDING')
  );

  if (isAgentsLoading || isTransactionsLoading) {
    return [[], true];
  }

  const agentsWithActiveTransactions = [];

  agents.docs.forEach((agent) => {
    const activeTransactions = allActiveTransactions.docs.filter((doc) => {
      return agent.ref.isEqual(doc.data().agent);
    });

    if (activeTransactions.length > 0) {
      agentsWithActiveTransactions.push({
        id: agent.id,
        name: agent.data().name,
        activeTransactions,
      });
    }
  });
  const isLoading = isAgentsLoading || isTransactionsLoading;
  return [agentsWithActiveTransactions, isLoading];
}

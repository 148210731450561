import PropTypes from 'prop-types';
import {
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  IconButton,
  PopoverArrow,
  PopoverCloseButton,
  List,
  ListItem,
  ListIcon,
  Tooltip,
  HStack,
} from '@chakra-ui/react';
import {
  VscLaw,
  VscMail,
  VscMilestone,
  VscHome,
  VscKey,
} from 'react-icons/vsc';
import { AiFillBank } from 'react-icons/ai';
import { AtSignIcon } from '@chakra-ui/icons';
import { useAgentDataOnce } from '../../resources/agents';
import CopyButton from '@common/CopyButton';

function Contact({ label, icon, title, subtitle, emails = '' }) {
  const emailsList = emails && emails.length > 0 ? emails.split('\n') : null;
  return (
    <ListItem fontSize="sm">
      <Tooltip label={label} placement="left">
        <HStack spacing={0}>
          <ListIcon as={icon} color="teal.500" />
          <Text fontWeight="semibold" isTruncated pr={1}>
            {title}
          </Text>
          {subtitle && (
            <>
              &middot;
              <Text
                pl={1}
                fontWeight="semibold"
                textStyle="secondary"
                fontSize="xs"
                isTruncated
              >
                {subtitle}
              </Text>
            </>
          )}
        </HStack>
      </Tooltip>
      {emailsList ? (
        <List ml={6}>
          {emailsList.map((email) => (
            <ListItem key={email}>
              <ListIcon as={VscMail} color="teal.500" />
              <CopyButton key={email} text={email} fontWeight="normal">
                {email}
              </CopyButton>
            </ListItem>
          ))}
          <ListItem>
            <CopyButton color="teal.500" key={emails} text={emails} icon={null}>
              Copy All
            </CopyButton>
          </ListItem>
        </List>
      ) : (
        <Box ml={6}>N/A</Box>
      )}
    </ListItem>
  );
}

Contact.propTypes = {
  color: PropTypes.string,
  details: PropTypes.node,
  emails: PropTypes.string,
  icon: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool,
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

function Contacts({ transaction }) {
  const data = transaction.exists ? transaction.data() : {};
  const agentPath = transaction.exists ? data.agent.path : null;
  const [agentData = {}, isLoadingAgent, error] = useAgentDataOnce(agentPath);

  if (error) {
    throw new Error(`unable to load agent. ${error}`);
  }

  const buyer = (
    <Contact
      isLoaded={!isLoadingAgent}
      icon={VscKey}
      label="Buyer"
      title={data.buyer}
      emails={data.buyerEmail}
    />
  );

  const seller = (
    <Contact
      isLoaded={!isLoadingAgent}
      label="Seller"
      icon={VscHome}
      title={data.seller}
      emails={data.sellerEmail}
    />
  );

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton icon={<AtSignIcon />} label="Contacts" />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Contacts</PopoverHeader>
        <PopoverBody>
          <List spacing={4} stylePosition="outside">
            <Contact
              isLoaded={!isLoadingAgent}
              label="Agent"
              icon={VscMilestone}
              title={agentData.name || ''}
              emails={agentData.email}
            />
            {data.isRepresentingBuyer && data.buyer && buyer}
            {data.isRepresentingSeller && data.seller && seller}
            {data.coopAgent && (
              <Contact
                isLoaded={!isLoadingAgent}
                label="Co-Op Agent"
                icon={VscMilestone}
                title={data.coopAgent}
                emails={data.coopAgentEmail}
              />
            )}
            {!data.isRepresentingBuyer && data.buyer && buyer}
            {!data.isRepresentingSeller && data.seller && seller}
            {data.lender && (
              <Contact
                isLoaded={!isLoadingAgent}
                label="Lender"
                icon={AiFillBank}
                title={data.lender}
                subtitle={data.lenderContact}
                emails={data.lenderContactEmail}
              />
            )}
            {data.closingAttorney && (
              <Contact
                isLoaded={!isLoadingAgent}
                label="Closing Attorney"
                icon={VscLaw}
                title={data.closingAttorney}
                subtitle={data.precloser}
                emails={data.precloserEmail}
              />
            )}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

Contacts.propTypes = {
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.any,
    ref: PropTypes.any,
  }),
};

export default Contacts;

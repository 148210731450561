import PropTypes from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import {
  Flex,
  Checkbox,
  VisuallyHidden,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuItem,
  MenuOptionGroup,
  MenuDivider,
  Avatar,
  Icon,
  Link,
  ButtonGroup,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import {
  VscEllipsis,
  VscSymbolEvent,
  VscWorkspaceUnknown,
} from 'react-icons/vsc';
import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';
import DeleteConfirmationModal from '@common/DeleteConfirmationModal';
import { updateTask, deleteTask, tasksCollection } from '../resources/tasks';
import { useTransaction } from '../resources/transactions';
import { useCollaboratorsList } from '../resources/collaborators';
import DateInput, { getTransactionTimeline } from '@common/DateInput';
import { useCurrentUser } from '../resources/auth';
import { normalizeTimezone } from '@common/utils/date';

function StartDate({ taskData, onChange }) {
  const [transaction = {}] = useTransaction(taskData.parent);
  const baseDates = useMemo(
    () =>
      transaction && transaction.exists
        ? getTransactionTimeline(transaction.data())
        : {},
    [transaction]
  );
  const isOverdue =
    taskData.startDate && !taskData.isComplete
      ? isPast(addDays(normalizeTimezone(taskData.startDate.toDate()), 1)) // Add a day since tasks should be before today to be overdue
      : false;
  return (
    <DateInput
      color={isOverdue ? 'red.500' : undefined}
      baseDates={baseDates}
      value={
        taskData.startDate
          ? normalizeTimezone(taskData.startDate.toDate())
          : undefined
      }
      onChange={onChange}
    />
  );
}

StartDate.propTypes = {
  onChange: PropTypes.func.isRequired,
  taskData: PropTypes.shape({
    isComplete: PropTypes.bool.isRequired,
    parent: PropTypes.any,
    startDate: PropTypes.object,
  }).isRequired,
};

function Task({ task }) {
  const [taskData, setTaskData] = useState(task.data());
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentUser] = useCurrentUser();
  const collaborators = useCollaboratorsList();
  const assignee = collaborators.find((c) => c.uid === taskData.assignee);

  useEffect(() => {
    const unsubscribe = tasksCollection.doc(task.id).onSnapshot((snapshot) => {
      if (snapshot.metadata.hasPendingWrites) {
        setTaskData(snapshot.data());
      }
    });
    return () => unsubscribe();
  }, [task.id]);

  return (
    <>
      <Flex
        py={2}
        px={4}
        justifyContent="stretch"
        layerStyle="task"
        flexWrap="wrap"
        data-selected={taskData.isComplete ? 'true' : undefined}
      >
        <Checkbox
          size="lg"
          isChecked={taskData.isComplete}
          onChange={(event) => {
            updateTask(task, { isComplete: event.target.checked });
          }}
        >
          <VisuallyHidden>Mark as complete</VisuallyHidden>
        </Checkbox>
        <Editable
          flex="2"
          minW="200px"
          mr={4}
          selectAllOnFocus={false}
          defaultValue={taskData.text}
          placeholder="Add task..."
          onSubmit={(text) => updateTask(task, { text })}
        >
          <EditableInput />
          <EditablePreview />
        </Editable>

        <ButtonGroup
          alignItems="center"
          spacing={1}
          flex={1}
          justifyContent="flex-end"
        >
          {taskData.isUrgent && <Icon as={VscSymbolEvent} color="red.500" />}
          {taskData.isWaiting && (
            <Icon as={VscWorkspaceUnknown} color="purple.500" />
          )}
          {taskData.parent && (
            <Link
              className="task-parent"
              color="teal.500"
              fontSize="xs"
              fontWeight="semibold"
              whiteSpace="nowrap"
              as={RouterLink}
              to={'/' + taskData.parent.path}
            >
              {taskData.parentDisplayName}
            </Link>
          )}

          <StartDate
            taskData={taskData}
            onChange={(startDate = null) => {
              updateTask(task, { startDate });
            }}
          />
          {assignee && (
            <Avatar
              className="task-assignee"
              size="xs"
              src={assignee.photoURL}
            />
          )}
          <div>
            <Menu autoSelect={false} isLazy>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<VscEllipsis />}
                size="sm"
                variant="ghost"
              />
              <MenuList zIndex="dropdown">
                <MenuItem
                  icon={<VscSymbolEvent />}
                  onClick={() => {
                    updateTask(task, { isUrgent: !taskData.isUrgent });
                  }}
                >
                  {taskData.isUrgent ? 'Mark not urgent' : 'Mark urgent'}
                </MenuItem>
                <MenuItem
                  icon={<VscWorkspaceUnknown />}
                  onClick={() => {
                    updateTask(task, { isWaiting: !taskData.isWaiting });
                  }}
                >
                  {taskData.isWaiting ? 'Mark not waiting' : 'Mark waiting'}
                </MenuItem>

                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    updateTask(task, { assignee: currentUser.uid });
                  }}
                >
                  Assign to me
                </MenuItem>
                <MenuDivider />

                <MenuOptionGroup
                  title="Assign to"
                  onChange={(changed) => {
                    if (changed === '') {
                      changed = null;
                    }
                    updateTask(task, { assignee: changed });
                  }}
                  value={taskData.assignee || ''}
                  type="radio"
                >
                  {collaborators.map((collaborator) => (
                    <MenuItemOption
                      key={collaborator.uid}
                      value={collaborator.uid}
                    >
                      {collaborator.shortName}
                    </MenuItemOption>
                  ))}
                  <MenuItemOption key="unassigned" value="">
                    Unassigned
                  </MenuItemOption>
                </MenuOptionGroup>

                <MenuDivider />
                <MenuItem
                  icon={<DeleteIcon />}
                  color="red.600"
                  onClick={() => setIsDeleting(true)}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </ButtonGroup>
      </Flex>
      <DeleteConfirmationModal
        successMessage="Task deleted successfully"
        errorMessage="Unable to delete task"
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onConfirm={() => deleteTask(task)}
      />
    </>
  );
}

Task.propTypes = {
  task: PropTypes.object,
};

export default Task;

import { useReducer, useEffect } from 'react';

function reducer(state, nextValue) {
  if (nextValue.type === 'RESET') {
    return {
      isTouched: false,
      value: nextValue.baseValue,
    };
  }

  return {
    isTouched: nextValue !== state.value,
    value: nextValue,
  };
}

export function useBlockUnload(shouldBlock) {
  useEffect(() => {
    const handleBeforeunload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (shouldBlock) {
      window.addEventListener('beforeunload', handleBeforeunload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [shouldBlock]);
}

function useDraftValue(baseValue, isSaving) {
  const [state, dispatch] = useReducer(reducer, {
    value: baseValue,
    isTouched: false,
  });

  useEffect(() => {
    dispatch({ type: 'RESET', baseValue });
  }, [baseValue]);

  useBlockUnload(state.isTouched || isSaving);

  return [state.value, dispatch];
}

export default useDraftValue;

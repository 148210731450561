import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Button,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useDraftDocument from '@common/useDraftDocument';
import AgentSelect from '@common/AgentSelect';
import {
  DEFAULT_CONTRACT_FEE,
  DEFAULT_TERMINATION_FEE,
} from '../constants/fees';
import {
  getInitialTransactionState,
  createTransaction,
} from '../resources/transactions';

function NewTransaction({ isOpen, onClose }) {
  const toast = useToast();
  const ref = useRef();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);

  const [selectedAgent, setSelectedAgent] = useState();

  const [newTransaction, setNewTransaction] = useDraftDocument(
    getInitialTransactionState()
  );
  const defaultShortName = newTransaction.name
    .replace(/\d/g, '')
    .trim()
    .split(' ')[0];
  const isValid = !!(
    newTransaction.name &&
    newTransaction.fee &&
    selectedAgent
  );
  const handleSave = async (event) => {
    event.preventDefault();
    try {
      setIsSaving(true);
      newTransaction.agent = selectedAgent.ref;
      newTransaction.shortName = newTransaction.shortName || defaultShortName;
      const agentChecklists = selectedAgent.data().checklists;
      if (agentChecklists) {
        newTransaction.checklists = newTransaction.checklists.map((list) => {
          if (agentChecklists[list.title]) {
            return {
              ...list,
              items: [...list.items, ...agentChecklists[list.title]],
            };
          }

          return list;
        });
      }

      const createdTransaction = await createTransaction(newTransaction);

      onClose();
      history.push('/' + createdTransaction.path + '?new');
    } catch (error) {
      setIsSaving(false);
      Sentry.captureException(error);
      toast({
        status: 'error',
        title: 'An error occurred.',
        description: 'Unable to create transaction.',
        isClosable: true,
      });
    }
  };

  return (
    <Modal initialFocusRef={ref} size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSave} autoComplete="off" noValidate>
          <ModalHeader>New Transaction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              <FormControl id="newTransactionName">
                <FormLabel>Name</FormLabel>
                <Input
                  ref={ref}
                  placeholder="Untitled"
                  value={newTransaction.name}
                  onChange={(event) => {
                    setNewTransaction({ name: event.target.value });
                  }}
                />
              </FormControl>
              <FormControl id="newTransactionShortName">
                <FormLabel>Short Name</FormLabel>
                <Input
                  value={newTransaction.shortName}
                  placeholder={defaultShortName}
                  onChange={(event) => {
                    setNewTransaction({ shortName: event.target.value });
                  }}
                />
              </FormControl>
              <HStack>
                <FormControl id="newTransactionAgent" flex={2}>
                  <FormLabel>Agent</FormLabel>
                  <AgentSelect
                    value={selectedAgent ? selectedAgent.id : null}
                    onChange={(selected) => {
                      setNewTransaction({
                        fee:
                          selected.agent.data().defaultFee ||
                          DEFAULT_CONTRACT_FEE,
                        terminationFee:
                          selected.agent.data().defaultTerminationFee ||
                          DEFAULT_TERMINATION_FEE,
                      });
                      setSelectedAgent(selected.agent);
                    }}
                  />
                </FormControl>
                <FormControl id="newTransactionFee" flex={1}>
                  <FormLabel>Fee</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      width="1em"
                      children="$"
                    />
                    <Input
                      pl="1em"
                      value={newTransaction.fee}
                      onChange={(event) => {
                        setNewTransaction({ fee: event.target.value });
                      }}
                    />
                  </InputGroup>
                </FormControl>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="teal"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSaving}
              >
                Create transaction
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

NewTransaction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewTransaction;

import PropTypes from 'prop-types';
import {
  InputGroup,
  FormErrorMessage,
  Input,
  Spinner,
  Skeleton,
  InputRightElement,
  FormHelperText,
  Flex,
} from '@chakra-ui/react';
import { formatDate, calculateDateFromDuration } from '@common/utils/date';
import FormField from '@common/FormField';
import useDraftValue from '@common/useDraftValue';
import { useTransactionMutation } from '../../../resources/transactions';

function Duration({ label, name, document, ...props }) {
  const data = document.exists ? document.data() : {};
  const key = Array.isArray(name) ? name.join('.') : name;
  let value = data[name] !== undefined ? data[name] : '';
  // Break up nested fields (like exhibits)
  if (Array.isArray(name)) {
    value = data[name[0]] !== undefined ? data[name[0]][name[1]] : '';
  }

  const [update, isUpdating, error] = useTransactionMutation(document);
  const [draftValue, setDraftValue] = useDraftValue(value, isUpdating);

  return (
    <FormField label={label} isInvalid={!!error}>
      <Skeleton isLoaded={document.exists}>
        <Flex>
          <InputGroup w="10ch" size="sm" {...props}>
            <Input
              inputMode="tel"
              value={draftValue}
              onChange={(event) => {
                const num = Number(event.target.value);
                setDraftValue(isNaN(num) ? '' : num);
              }}
              onBlur={(event) => {
                if (draftValue !== data[name]) {
                  update({ [key]: draftValue });
                }
                // double blurs are happening for some reason so this prevents it
                event.stopPropagation();
              }}
              {...props}
            />
            <InputRightElement w="3rem">
              {isUpdating ? <Spinner /> : ' days '}
            </InputRightElement>
          </InputGroup>
          {draftValue !== null && data.bindingDate ? (
            <FormHelperText ml={2}>
              ends{' '}
              {formatDate(
                calculateDateFromDuration(data.bindingDate, draftValue),
                'M/d'
              )}
            </FormHelperText>
          ) : null}
        </Flex>
      </Skeleton>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormField>
  );
}

Duration.propTypes = {
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Duration;

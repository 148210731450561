import PropTypes from 'prop-types';
import React from 'react';
import { Button, Link } from '@chakra-ui/react';

const LinkButton = React.forwardRef(
  ({ component: Component = Button, navigate, ...props }, ref) => {
    return (
      <Component
        as={Link}
        textDecoration="none"
        onClick={(event) => {
          event.preventDefault();
          navigate();
        }}
        isActive={props['aria-current']}
        ref={ref}
        {...props}
      />
    );
  }
);

LinkButton.propTypes = {
  'aria-current': PropTypes.string,
  component: PropTypes.any,
  navigate: PropTypes.func,
};

export default LinkButton;

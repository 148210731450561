import { useState } from 'react';
import {
  Box,
  Center,
  Container,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  SlideFade,
  Button,
} from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import { authenticate } from './resources/auth';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = () => {
    setIsLoading(true);
    authenticate()
      .then(() => setIsLoading(false))
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  return (
    <Box w="100vw" h="100vh" paddingTop="25vh">
      <Container centerContent>
        <SlideFade in={true} offsetY="20px">
          <Stack w="100%" spacing={4}>
            <Center>
              <img
                style={{ width: '50%' }}
                src="/logo-mark.png"
                alt="Closing Bot"
              />
            </Center>
            <Center>
              <Button
                leftIcon={<FaGoogle />}
                colorScheme="teal"
                size="lg"
                isLoading={isLoading}
                loadingText="Logging in..."
                onClick={handleClick}
              >
                Log In With Google
              </Button>
            </Center>
            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>Oh no!</AlertTitle>
                <AlertDescription>
                  There was a problem logging you in.
                </AlertDescription>
              </Alert>
            )}
          </Stack>
        </SlideFade>
      </Container>
    </Box>
  );
};

export default Login;

import { extendTheme } from '@chakra-ui/react';
import { mode, transparentize } from '@chakra-ui/theme-tools';

const theme = extendTheme({
  styles: {
    global: {
      a: {
        _hover: {
          textDecoration: 'none',
        },
        '.chakra-ui-dark &': { color: 'teal.300' },
      },
    },
  },

  textStyles: {
    secondary: {
      color: 'gray.600',
      '.chakra-ui-dark &': { color: 'gray.500' },
    },
  },

  layerStyles: {
    canvas: {
      bg: 'gray.50',
      '.chakra-ui-dark &': { borderColor: 'gray.700', bg: 'gray.800' },
    },

    panel: {
      bg: 'white',
      borderColor: 'gray.200',
      '.chakra-ui-dark &': { borderColor: 'gray.700', bg: 'gray.900' },
    },

    section: {
      bg: 'white',
      borderColor: 'gray.200',
      borderRadius: 'md',
      '.chakra-ui-dark &': { borderColor: 'gray.700', bg: 'gray.900' },
    },

    task: {
      borderColor: 'gray.200',
      borderBottomWidth: '1px',
      '&[data-selected] > *': {
        opacity: 0.2,
      },
      '.chakra-ui-dark &': {
        borderColor: 'gray.600',
      },

      _hover: {
        bg: 'cyan.50',
        '.chakra-ui-dark &': { bg: 'gray.700' },
      },
    },

    highlighted: {
      _hover: {
        bg: 'cyan.50',
        '.chakra-ui-dark &': { bg: 'gray.700' },
      },
    },

    selected: {
      _pressed: {
        bg: 'gray.500',
        color: 'white',
        '.chakra-ui-dark &': { bg: 'teal.600' },
      },
      _selected: {
        bg: 'gray.500',
        color: 'white',
        '.chakra-ui-dark &': { bg: 'teal.600' },
      },
      _highlighted: {
        bg: 'teal.500',
        color: 'white',
      },
    },
  },

  components: {
    Spinner: {
      baseStyle: {
        color: 'teal.500',
      },
    },

    // Input: {
    //   variants: {
    //     outline: {
    //       field: {
    //         borderRadius: 'md',
    //         bg: 'white',
    //         '.chakra-ui-dark &': { bg: 'gray.700' },
    //       },
    //     },
    //   },
    // },

    // Checkbox: {
    //   baseStyle: {
    //     control: {
    //       bg: 'white',
    //       '.chakra-ui-dark &': { bg: 'gray.500' },
    //     },
    //   },
    // },

    // Select: {
    //   variants: {
    //     outline: {
    //       field: {
    //         borderRadius: 'md',
    //         bg: 'white',
    //         '.chakra-ui-dark &': { bg: 'gray.700' },
    //       },
    //     },
    //   },
    // },

    // Textarea: {
    //   variants: {
    //     outline: {
    //       borderRadius: 'md',
    //       bg: 'white',
    //       '.chakra-ui-dark &': { bg: 'gray.700' },
    //     },
    //   },
    // },

    Link: {
      baseStyle: {
        color: 'teal.600',
      },
    },

    Button: {
      variants: {
        mainNav: (props) => {
          return {
            display: 'flex',
            justifyContent: 'flex-start',
            border: '1px solid transparent',
            bg: 'transparent',
            color: 'gray.800',
            w: '100%',
            _focus: {
              boxShadow: 'none',
              borderColor: 'gray.100',
            },
            _hover: {
              textDecoration: 'none',
              color: 'gray.800',
              bg: 'gray.100',
            },
            _active: {
              bg: 'teal.500',
              color: 'white',
            },
            '.chakra-ui-dark &': {
              color: 'whiteAlpha.900',
              _focus: {
                boxShadow: 'none',
                borderColor: 'whiteAlpha.200',
              },
              _hover: {
                textDecoration: 'none',
                color: 'whiteAlpha.900',
                bg: 'whiteAlpha.200',
              },
            },
          };
        },
        soft: (props) => {
          const { colorScheme: c, theme } = props;
          if (c === 'gray') {
            return {
              bg: mode('gray.100', 'whiteAlpha.200')(props),
              color: mode('inherit', 'whiteAlpha.900')(props),
              _hover: {
                bg: mode('gray.200', 'whiteAlpha.200')(props),
              },
              _active: { bg: mode('gray.200', 'whiteAlpha.300')(props) },
            };
          }

          const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
          const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

          return {
            color: mode(`${c}.600`, `${c}.200`)(props),
            bg: mode(`${c}.50`, darkHoverBg)(props),
            _hover: {
              bg: mode(`${c}.100`, darkHoverBg)(props),
            },
            _active: {
              bg: mode(`${c}.200`, darkActiveBg)(props),
            },
          };
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'semibold',
        color: 'gray.500',
        '.chakra-ui-dark &': { color: 'gray.400' },
      },
    },

    Popover: {
      parts: ['popper'],
      baseStyle: (props) => ({
        popper: {
          zIndex: 'popover',
          maxW: props.width ? props.width : 'xs',
          w: '100%',
        },
      }),
    },

    Modal: {
      baseStyle: (props) => ({
        footer: {
          ...props.footer,
          bg: 'gray.50',
          borderTopWidth: '1px',
          borderBottomRadius: 'md',
          '.chakra-ui-dark &': { borderColor: 'gray.600', bg: 'gray.700' },
        },
      }),
    },

    Editable: {
      baseStyle: {
        preview: {
          border: '1px dashed transparent',
          _hover: {
            borderColor: 'blue.400',
          },
        },
        input: {
          border: '1px solid transparent',
          borderRadius: 0,
          _focus: {
            borderRadius: 0,
            boxShadow: 'none',
            borderBottomColor: 'teal.500',
          },
        },
      },
    },
  },
});

export default theme;

import PropTypes from 'prop-types';
import {
  FormErrorMessage,
  Textarea,
  Skeleton,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import FormField from '@common/FormField';
import useDraftValue from '@common/useDraftValue';
import { useTransactionMutation } from '../../../resources/transactions';

const VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function findInvalidEmails(str) {
  return str.split('\n').filter((line) => {
    if (line.length === 0) {
      return false;
    }

    return !VALID_EMAIL_REGEX.test(line);
  });
}

function cleanEmails(emails = '') {
  const cleaned = emails
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
  return cleaned.join('\n');
}

function Email({ label, name, document, ...props }) {
  const data = document.exists ? document.data() : {};
  const key = Array.isArray(name) ? name.join('.') : name;
  let value = data[name] || '';
  // Break up nested fields (like exhibits)
  if (Array.isArray(name)) {
    value = data[name[0]] ? data[name[0]][name[1]] : '';
  }

  const invalidEmails = findInvalidEmails(value);
  const [update, isUpdating, error] = useTransactionMutation(document);
  const [draftValue, setDraftValue] = useDraftValue(value, isUpdating);

  return (
    <FormField
      label={label}
      isInvalid={invalidEmails.length > 0}
      position="relative"
    >
      <Skeleton isLoaded={document.exists}>
        <InputGroup variant="outline">
          <Textarea
            size="sm"
            variant="outline"
            value={draftValue}
            onChange={(event) => setDraftValue(event.target.value)}
            onBlur={() => {
              setDraftValue(cleanEmails(draftValue));
              if (draftValue !== value) {
                update({ [key]: cleanEmails(draftValue) });
              }
            }}
            {...props}
          />
          {isUpdating && (
            <InputRightElement>
              <Spinner />
            </InputRightElement>
          )}
        </InputGroup>
      </Skeleton>
      {invalidEmails.map((invalidEmail) => (
        <FormErrorMessage key={invalidEmail}>
          '{invalidEmail}' is not a valid e-mail address.
        </FormErrorMessage>
      ))}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormField>
  );
}

Email.propTypes = {
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Email;

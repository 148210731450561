import ReactDOM from 'react-dom';
import { ColorModeScript } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import App from './App';
import theme from './theme';
import './index.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ed53c1c547814586940c4ba72e066620@sentry.io/1802975',
  });
}

const rootNode = document.getElementById('root');
ReactDOM.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </>,
  rootNode
);

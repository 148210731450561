import PropTypes from 'prop-types';
import { Box, Skeleton, HStack, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useAgentDataOnce } from '../../resources/agents';
import FormField, { Fieldset } from '@common/FormField';
import { Text, Email, Checkbox, Money } from './Fields';

function Agent({ transaction }) {
  const agentPath = transaction.exists ? transaction.data().agent.path : null;
  const [agentData = {}, isLoading] = useAgentDataOnce(agentPath);

  return (
    <>
      <FormField label="Name">
        <Skeleton isLoaded={!isLoading}>
          <Link fontSize="xs" color="teal" as={RouterLink} to={`/${agentPath}`}>
            {agentData.name}
          </Link>
        </Skeleton>
      </FormField>
      <FormField label="E-mail" maxW="100%" overflow="hidden">
        <Box>
          <Skeleton isLoaded={!isLoading}>
            {agentData.email ? (
              <Link fontSize="sm" href={`mailto:${agentData.email}`}>
                {agentData.email}
              </Link>
            ) : (
              'N/A'
            )}
          </Skeleton>
        </Box>
      </FormField>
      <FormField label="Representing" alignItems="center">
        <HStack>
          <Checkbox name="isRepresentingBuyer" document={transaction}>
            Buyer
          </Checkbox>
          <Checkbox name="isRepresentingSeller" document={transaction}>
            Seller
          </Checkbox>
        </HStack>
      </FormField>
      <Money label="Fee" name="fee" document={transaction} />
      <Money
        label="Termination Fee"
        name="terminationFee"
        document={transaction}
      />
    </>
  );
}

Agent.propTypes = {
  transaction: PropTypes.any,
};

function TransactionParties({ transaction }) {
  return (
    <>
      <Fieldset
        legend="Agent"
        sx={{
          '--form-label-width': '120px',
        }}
      >
        {transaction.exists ? (
          <Agent transaction={transaction} />
        ) : (
          <>
            <Skeleton h="2em" mb={1} />
            <Skeleton h="2em" />
            <Skeleton h="2em" />
          </>
        )}
      </Fieldset>
      <Fieldset
        legend="Co-Op Agent"
        sx={{
          '--form-label-width': '140px',
        }}
      >
        <Text label="Name" name="coopAgent" document={transaction} />
        <Email label="E-mail" name="coopAgentEmail" document={transaction} />
      </Fieldset>
      <Fieldset legend="Buyer">
        <Text label="Name" name="buyer" document={transaction} />
        <Email label="E-mail" name="buyerEmail" document={transaction} />
      </Fieldset>
      <Fieldset legend="Seller">
        <Text label="Name" name="seller" document={transaction} />
        <Email label="E-mail" name="sellerEmail" document={transaction} />
      </Fieldset>
      <Fieldset legend="Lender">
        <Text label="Company" name="lender" document={transaction} />
        <Text label="Contact" name="lenderContact" document={transaction} />
        <Email
          label="E-mail"
          name="lenderContactEmail"
          document={transaction}
        />
      </Fieldset>
      <Fieldset legend="Closing Attorney">
        <Text label="Firm" name="closingAttorney" document={transaction} />
        <Text label="Pre-Closer" name="precloser" document={transaction} />
        <Email label="E-mail" name="precloserEmail" document={transaction} />
      </Fieldset>
    </>
  );
}

TransactionParties.propTypes = {
  agent: PropTypes.shape({
    data: PropTypes.func,
    id: PropTypes.any,
  }),
  transaction: PropTypes.any,
};

export default TransactionParties;

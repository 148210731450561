import PropTypes from 'prop-types';
import {
  Container,
  Code,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
} from '@chakra-ui/react';
import {
  showReportDialog,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';
import { useCurrentUser } from '../resources/auth';

export function ErrorScreen({ error, eventId }) {
  const [currentUser] = useCurrentUser();
  console.error(error.toString());
  return (
    <Container centerContent h="100%" mt={20}>
      <Alert
        status="error"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        borderRadius="md"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Oopsie Doopsie Doo!
        </AlertTitle>
        <AlertDescription>
          <Text>We encountered the following error.</Text>
          <Code colorScheme="blackAlpha" m={4} p={4}>
            {error.toString()}
          </Code>
        </AlertDescription>
        <Button
          mt={4}
          colorScheme="red"
          onClick={() => {
            showReportDialog({
              eventId,
              user: {
                name: currentUser.displayName,
                email: currentUser.email,
              },
              title: "Tell Jarrett what's wrong",
              subtitle: 'The error has already been logged.',
              subtitle2:
                'If you want to add more info, just describe what you were doing when the error occured.',
            });
          }}
        >
          Report this error
        </Button>
      </Alert>
    </Container>
  );
}

ErrorScreen.propTypes = {
  currentUser: PropTypes.shape({
    displayName: PropTypes.any,
    email: PropTypes.any,
  }).isRequired,
  error: PropTypes.object,
  eventId: PropTypes.string,
};

function ErrorBoundary({ children, fallback: Fallback = ErrorScreen }) {
  const [currentUser] = useCurrentUser();

  return (
    <SentryErrorBoundary
      fallback={(props) => <Fallback {...props} currentUser={currentUser} />}
    >
      {children}
    </SentryErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.any,
};

export default ErrorBoundary;

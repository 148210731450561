import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  useDisclosure,
  Spinner,
  Center,
  Button,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import {
  DEFAULT_CONTRACT_FEE,
  DEFAULT_TERMINATION_FEE,
} from '../constants/fees';
import Panel, { PanelHeader, PanelBody } from '@common/Panel';
import { useAgents, agentsCollection } from '../resources/agents';
import { formatMoney } from '@common/utils/money';
import NewAgent from './NewAgent';

function Agents() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [agents, isLoading, error] = useAgents(
    agentsCollection.orderBy('name', 'asc')
  );

  if (error) {
    throw new Error(`Unable to fetch agents. ${error}`);
  }

  return (
    <Panel>
      <Helmet>
        <title>Closing Bot: Agents</title>
      </Helmet>
      <PanelHeader
        title="Agents"
        actions={
          <Button
            variant="solid"
            colorScheme="teal"
            onClick={onOpen}
            leftIcon={<AddIcon />}
          >
            New Agent
          </Button>
        }
      >
        Agents
      </PanelHeader>
      <PanelBody p={0}>
        {isLoading ? (
          <Center w="100vw" h="100vh">
            <Spinner />
          </Center>
        ) : (
          <Table fontSize={{ base: 'xs', md: 'md' }}>
            <Thead>
              <Tr>
                <Th top={0} position="sticky">
                  Name
                </Th>
                <Th
                  top={0}
                  position="sticky"
                  display={['none', null, 'table-cell']}
                >
                  Email
                </Th>
                <Th top={0} position="sticky" isNumeric>
                  Fee
                </Th>
                <Th top={0} position="sticky" isNumeric>
                  Termination Fee
                </Th>
                <Th
                  top={0}
                  position="sticky"
                  display={['none', null, 'table-cell']}
                >
                  &nbsp;
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {agents.docs.map((doc) => {
                const agent = isLoading ? {} : doc.data();
                return (
                  <Tr key={doc.id} layerStyle="highlighted">
                    <Td>
                      <Link as={RouterLink} to={`/agents/${doc.id}`} size="sm">
                        {agent.name}
                      </Link>
                    </Td>
                    <Td display={['none', null, 'table-cell']}>
                      {agent.email}
                    </Td>

                    <Td isNumeric>
                      {formatMoney(agent.defaultFee || DEFAULT_CONTRACT_FEE)}
                    </Td>
                    <Td isNumeric>
                      {formatMoney(
                        agent.defaultTerminationFee || DEFAULT_TERMINATION_FEE
                      )}
                    </Td>
                    <Td display={['none', null, 'table-cell']} isNumeric>
                      <Link as={RouterLink} to={`/agents/${doc.id}`}>
                        View
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </PanelBody>
      <NewAgent
        key={isOpen ? '_open' : '_close'}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Panel>
  );
}

export default Agents;

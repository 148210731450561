import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Editable,
  EditablePreview,
  EditableInput,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  Button,
  ButtonGroup,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  useDisclosure,
  Skeleton,
  FormControl,
  FormLabel,
  VStack,
} from '@chakra-ui/react';
import {
  CopyIcon,
  EditIcon,
  DeleteIcon,
  ChevronDownIcon,
  TimeIcon,
} from '@chakra-ui/icons';
import * as Sentry from '@sentry/react';
import { VscEllipsis } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AgentSelect from '@common/AgentSelect';
import CopyButton from '@common/CopyButton';
import {
  useTransactionMutation,
  deleteTransaction,
  cloneTransaction,
} from '../../resources/transactions';
import {
  useTasks,
  deleteTasks,
  completeTasks,
  tasksCollection,
} from '../../resources/tasks';
import {
  PAYMENT_STATUSES,
  CONTRACT_STATUSES,
} from '../../constants/transactionStatuses';

import { STATUSES } from '@common/TransactionStatus';
import DeleteConfirmationModal from '@common/DeleteConfirmationModal';
import { PanelHeader } from '@common/Panel';
import Contacts from './Contacts';
import History from './History';
import { useAgent } from 'resources/agents';

function Header({ transaction, isLoading, actions }) {
  const { name, status, paymentStatus } = transaction.exists
    ? transaction.data()
    : {};
  const history = useHistory();
  const [updateTransaction] = useTransactionMutation(transaction);
  const isDeletingDisclosure = useDisclosure();
  const isConfirmingPaymentDisclosure = useDisclosure();
  const historyModalDisclosure = useDisclosure();
  const replicateDisclosure = useDisclosure();
  const changeAgentDisclosure = useDisclosure();

  const handlePaymentStatusChange = (paymentStatus) => {
    if (paymentStatus === PAYMENT_STATUSES.PAID) {
      isConfirmingPaymentDisclosure.onOpen();
    } else {
      updateTransaction({ paymentStatus });
    }
  };

  return (
    <>
      {!isLoading && (
        <Helmet>
          <title>{name} - Closing Bot</title>
        </Helmet>
      )}
      <PanelHeader
        isLoading={isLoading}
        actions={
          <>
            {!isLoading && (
              <div>
                <Contacts transaction={transaction} />
              </div>
            )}
            <div>
              <Skeleton isLoaded={!isLoading}>
                <Menu autoSelect={false}>
                  <MenuButton
                    as={Button}
                    borderWidth="1px"
                    colorScheme={status ? STATUSES[status].color : ''}
                    variant="soft"
                    rightIcon={<ChevronDownIcon />}
                  >
                    {status ? STATUSES[status].title : ''}
                    {paymentStatus === PAYMENT_STATUSES.PAID && ' / Paid'}
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      value={status}
                      onChange={(changed) => {
                        updateTransaction({ status: changed });
                      }}
                      title="Contract Status"
                      type="radio"
                    >
                      {Object.keys(CONTRACT_STATUSES).map((contractStatus) => (
                        <MenuItemOption
                          key={contractStatus}
                          value={contractStatus}
                        >
                          {STATUSES[contractStatus].title}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                    {status !== CONTRACT_STATUSES.ACTIVE && (
                      <>
                        <MenuDivider />
                        <MenuOptionGroup
                          value={paymentStatus}
                          onChange={handlePaymentStatusChange}
                          title="Payment"
                          type="radio"
                        >
                          {Object.keys(PAYMENT_STATUSES).map(
                            (paymentStatus) => (
                              <MenuItemOption
                                key={paymentStatus}
                                value={paymentStatus}
                              >
                                {STATUSES[paymentStatus].title}
                              </MenuItemOption>
                            )
                          )}
                        </MenuOptionGroup>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </Skeleton>
            </div>
            <div>
              <Menu autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  variant="outline"
                  aria-label="Actions"
                  icon={<VscEllipsis />}
                />
                <MenuList zIndex="popover">
                  <MenuItem
                    icon={<TimeIcon />}
                    onClick={historyModalDisclosure.onOpen}
                  >
                    View History
                  </MenuItem>
                  <MenuItem
                    icon={<CopyIcon />}
                    onClick={replicateDisclosure.onOpen}
                  >
                    Replicate
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    icon={<EditIcon />}
                    onClick={changeAgentDisclosure.onOpen}
                    color="red.500"
                  >
                    Change Agent
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={isDeletingDisclosure.onOpen}
                    color="red.500"
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
            {actions}
          </>
        }
      >
        <Editable
          defaultValue={name}
          onSubmit={(changed) => updateTransaction({ name: changed })}
          p={1}
          flex={2}
          minW="100%"
        >
          {({ isEditing }) =>
            isEditing ? (
              <EditableInput minW="100%" />
            ) : (
              <>
                <EditablePreview />
                {!isLoading && <CopyButton text={name} />}
              </>
            )
          }
        </Editable>
      </PanelHeader>
      <DeleteConfirmationModal
        successMessage="Transaction deleted successfully"
        errorMessage="Unable to delete transaction"
        {...isDeletingDisclosure}
        onConfirm={async () => {
          history.push('/transactions');
          const tasks = await tasksCollection
            .where('parent', '==', transaction.ref)
            .get();
          await deleteTasks(tasks);
          return deleteTransaction(transaction);
        }}
      />
      {replicateDisclosure.isOpen && (
        <ReplicateConfirmationModal
          key={transaction.id}
          transaction={transaction}
          {...replicateDisclosure}
        />
      )}
      {changeAgentDisclosure.isOpen && (
        <ChangeAgentModal
          key={transaction.id}
          transaction={transaction}
          onUpdate={updateTransaction}
          {...changeAgentDisclosure}
        />
      )}

      {/* Don't load these unless they're open because they read data when opened */}
      {historyModalDisclosure.isOpen && (
        <History transaction={transaction} {...historyModalDisclosure} />
      )}
      {isConfirmingPaymentDisclosure.isOpen && (
        <PaidConfirmationModal
          transaction={transaction}
          {...isConfirmingPaymentDisclosure}
        />
      )}
    </>
  );
}

Header.propTypes = {
  actions: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.func,
    exists: PropTypes.bool,
    ref: PropTypes.any,
  }),
};

const ReplicateConfirmationModal = ({ transaction, isOpen, onClose }) => {
  const toast = useToast();
  const history = useHistory();
  const [agent, isLoading, error] = useAgent(transaction.data().agent.path);
  const [selectedAgent, setSelectedAgent] = useState();
  const [isCloning, setIsCloning] = useState(false);

  if (error) {
    throw new Error(error);
  }

  const handleConfirm = async () => {
    setIsCloning(true);
    try {
      const cloned = await cloneTransaction(transaction, selectedAgent);
      toast({
        title: 'Transaction replicated successfully.',
        status: 'success',
        isClosable: true,
      });
      onClose();
      history.push('/' + cloned.path);
    } catch (err) {
      Sentry.captureException(err);
      toast({
        title: 'An error occurred.',
        description: 'Unable to replicate this transaction.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setSelectedAgent(agent);
  }, [agent]);

  return transaction.exists ? (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Replicate {transaction.data().name}
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormControl id="newTransactionAgent" flex={2}>
              <FormLabel>Agent</FormLabel>
              <AgentSelect
                value={selectedAgent ? selectedAgent.id : null}
                onChange={(selected) => {
                  setSelectedAgent(selected.agent);
                }}
              />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              {!isCloning && <Button onClick={onClose}>Cancel</Button>}
              <Button
                colorScheme="teal"
                ml={3}
                onClick={handleConfirm}
                isLoading={isLoading || isCloning}
              >
                Replicate
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  ) : null;
};

ReplicateConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
    ref: PropTypes.any,
  }).isRequired,
};

const PaidConfirmationModal = ({ transaction, isOpen, onClose }) => {
  const toast = useToast();
  const [updateTransaction, isMutating, error] = useTransactionMutation(
    transaction
  );
  const [incompleteTasks] = useTasks(
    tasksCollection
      .where('parent', '==', transaction.ref)
      .where('isComplete', '==', false)
  );
  const handleConfirm = () => {
    updateTransaction({ paymentStatus: PAYMENT_STATUSES.PAID })
      .then(() => {
        if (!incompleteTasks.empty) {
          completeTasks(incompleteTasks.docs);
        }
      })
      .catch((err) => {
        Sentry.captureException(error || err);
        toast({
          status: 'error',
          title: `An error occurred. ${err}`,
          isClosable: true,
        });
      })
      .finally(() => onClose());
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Mark {transaction.data().name} as paid
          </AlertDialogHeader>
          <AlertDialogBody>
            The {incompleteTasks.size}{' '}
            {incompleteTasks.size > 1 ? 'tasks' : 'task'} assigned to this
            transaction will be marked as completed.
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              {!isMutating && <Button onClick={onClose}>Cancel</Button>}
              <Button
                colorScheme="teal"
                ml={3}
                onClick={handleConfirm}
                isLoading={isMutating}
              >
                Mark as paid
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

PaidConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
    ref: PropTypes.any,
  }).isRequired,
};

const ChangeAgentModal = ({ transaction, onUpdate, isOpen, onClose }) => {
  const toast = useToast();
  const [currentAgent, isLoading, error] = useAgent(
    transaction.data().agent.path
  );
  const [nextAgent, setNextAgent] = useState();
  const [isSaving, setIsSaving] = useState(false);

  if (error) {
    throw new Error(error);
  }

  const handleConfirm = async () => {
    setIsSaving(true);
    try {
      await onUpdate({ agent: nextAgent.ref });
      onClose();
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast({
        title: 'An error occurred.',
        description: 'Unable to replicate this transaction.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return transaction.exists ? (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Change agent on {transaction.data().name}
          </AlertDialogHeader>
          <AlertDialogBody>
            <VStack>
              <FormControl id="newTransactionAgent" flex={2}>
                <FormLabel>From</FormLabel>
                {currentAgent?.data().name}
              </FormControl>
              <FormControl id="newTransactionAgent" flex={2}>
                <FormLabel>To</FormLabel>
                <AgentSelect
                  value={nextAgent ? nextAgent.id : null}
                  onChange={(selected) => {
                    setNextAgent(selected.agent);
                  }}
                />
              </FormControl>
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              {!isSaving && <Button onClick={onClose}>Cancel</Button>}
              <Button
                colorScheme="teal"
                ml={3}
                onClick={handleConfirm}
                disabled={!nextAgent}
                isLoading={isLoading || isSaving}
              >
                Change
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  ) : null;
};

ChangeAgentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
    ref: PropTypes.any,
  }).isRequired,
};

export default Header;

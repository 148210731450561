import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';
import { Route, Switch, Redirect } from 'react-router';
import Login from './Login';
import MainNav from './MainNav';
import Transactions from './Transactions';
import Transaction from './Transactions/View';
import Tasks from './Tasks';
import Agents from './Agents';
import Agent from './Agents/View';
import ErrorBoundary from '@common/ErrorBoundary';

function Layout() {
  return (
    <Flex w="100vw" maxW="100vw" h="100vh" maxH="100vh">
      <MainNav />

      <Flex
        as="main"
        h="100vh"
        maxH="100vh"
        maxW="100%"
        flex="1 1 auto"
        justifyContent="stretch"
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route
            path="/dashboard"
            render={(props) => <Transactions {...props} />}
          />
          <Route
            path={`/transactions/:id`}
            render={(props) => <Transaction {...props} />}
          />
          <Route path="/agents/:id" component={Agent} />
          <Route path="/agents" component={Agents} />
          <Route path="/tasks" component={Tasks} />
        </Switch>
      </Flex>
    </Flex>
  );
}

function Main({ currentUser }) {
  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={currentUser ? Layout : Login} />
      </Switch>
    </ErrorBoundary>
  );
}

Main.propTypes = {
  currentUser: PropTypes.object,
};

export default Main;

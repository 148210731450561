import PropTypes from 'prop-types';
import { Money, Text, Duration } from './Fields';
import { Fieldset } from '@common/FormField';

function Costs({ transaction }) {
  return (
    <Fieldset legend="Costs & Earnest Money">
      <Money label="Sales Price" name="salesPrice" document={transaction} />
      <Money label="SPCC" name="spcc" document={transaction} />
      <Money label="EM Amount" name="emAmount" document={transaction} />
      <Duration label="EM Duration" name="emDuration" document={transaction} />
      <Text label="EM Holder" name="emHolder" document={transaction} />
    </Fieldset>
  );
}

Costs.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default Costs;

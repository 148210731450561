import PropTypes from 'prop-types';

import { Flex, FormErrorMessage, Spinner, Skeleton } from '@chakra-ui/react';
import { normalizeTimezone, parseDate } from '@common/utils/date';
import DateInput, { getTransactionTimeline } from '@common/DateInput';
import { useTransactionMutation } from '../../../resources/transactions';
import { useBlockUnload } from '@common/useDraftValue';
import FormField from '@common/FormField';

function Date({ label, name, document, ...props }) {
  const data = document.exists ? document.data() : {};
  const [update, isUpdating, error] = useTransactionMutation(document);
  useBlockUnload(isUpdating);

  return (
    <FormField label={label}>
      <Skeleton isLoaded={!props.isLoading}>
        <Flex>
          <DateInput
            label="Add"
            variant="outline"
            baseDates={getTransactionTimeline(data)}
            value={
              data[name] ? normalizeTimezone(data[name].toDate()) : undefined
            }
            justifyContent="flex-start"
            size="sm"
            onChange={(date) => {
              if (!date) {
                update({ [name]: null });
                return;
              }

              const parsedDate = parseDate(date);
              if (!parsedDate.isEqual(date)) {
                update({ [name]: parsedDate });
              }
            }}
          />
          {isUpdating && <Spinner ml={4} />}
        </Flex>
      </Skeleton>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormField>
  );
}

Date.propTypes = {
  document: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }).isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Date;

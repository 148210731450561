import PropTypes from 'prop-types';
import {
  Container,
  ButtonGroup,
  Flex,
  Heading,
  Spacer,
  Skeleton,
} from '@chakra-ui/react';

function PanelHeader({ actions, leftActions, isLoading, children, ...props }) {
  return (
    <Container
      as="header"
      layerStyle="panel"
      maxW="100%"
      borderBottomWidth="1px"
      position="sticky"
      zIndex="docked"
      top={0}
      p={0}
      h="56px"
      {...props}
    >
      <Flex alignItems="center" h="100%" p={4}>
        {leftActions && (
          <>
            <ButtonGroup
              display={['flex', null, null, 'none']}
              size="xs"
              variant="outline"
              alignItems="center"
            >
              {leftActions}
            </ButtonGroup>
            <ButtonGroup
              display={['none', null, null, 'flex']}
              size="sm"
              variant="outline"
              alignItems="center"
            >
              {leftActions}
            </ButtonGroup>
            <Spacer />
          </>
        )}

        {children && (
          <>
            <Skeleton
              isLoaded={!isLoading}
              minW={isLoading !== undefined ? '120px' : 'auto'}
              flex={3}
            >
              <Heading as="h1" size="sm" fontWeight="extrabold" isTruncated>
                {children}
              </Heading>
            </Skeleton>
            <Spacer />
          </>
        )}

        <ButtonGroup
          display={['flex', null, null, 'none']}
          ml={4}
          size="xs"
          variant="outline"
          alignItems="center"
        >
          {actions}
        </ButtonGroup>
        <ButtonGroup
          display={['none', null, null, 'flex']}
          ml={4}
          size="sm"
          variant="outline"
          alignItems="center"
        >
          {actions}
        </ButtonGroup>
      </Flex>
    </Container>
  );
}

PanelHeader.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  leftActions: PropTypes.node,
};

export default PanelHeader;

import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import {
  Flex,
  Text,
  List,
  ListItem,
  Checkbox,
  SimpleGrid,
  Stack,
  Spinner,
  Progress,
} from '@chakra-ui/react';
import { useTransactionMutation } from '../../resources/transactions';
import { useBlockUnload } from '@common/useDraftValue';

function getMapKey(list, title) {
  return (
    list.replace(' ', '') +
    '--' +
    title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '')
  ).toLowerCase();
}

function ChecklistItem({
  shiftKeyRef,
  transaction,
  mapKey,
  onToggle,
  children,
}) {
  const { checklistsMap = {} } = transaction.data();

  const handleToggle = (value) => {
    return onToggle({
      [`checklistsMap.${mapKey}`]: value,
    });
  };

  return (
    <ListItem key={mapKey}>
      <Checkbox
        _readOnly={{
          color: 'gray.300',
          textDecoration: 'line-through',
        }}
        isChecked={checklistsMap[mapKey] === true}
        isIndeterminate={checklistsMap[mapKey] === null}
        readOnly={checklistsMap[mapKey] === null}
        onChange={(event) => {
          if (shiftKeyRef.current) {
            handleToggle(checklistsMap[mapKey] === null ? false : null);
          } else {
            handleToggle(event.target.checked);
          }
        }}
      >
        {children}
      </Checkbox>
    </ListItem>
  );
}

ChecklistItem.propTypes = {
  children: PropTypes.node.isRequired,
  mapKey: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  shiftKeyRef: PropTypes.shape({
    current: PropTypes.bool,
  }),
  transaction: PropTypes.shape({
    data: PropTypes.func,
  }),
};

function Checklist({ list, checklistsMap, transaction, shiftKey }) {
  const [updateTransaction, isUpdating] = useTransactionMutation(transaction);
  useBlockUnload(isUpdating);
  const finished = list.items.filter((item) => {
    const key = getMapKey(list.title, item);
    return checklistsMap[key] === true || checklistsMap[key] === null;
  });
  return (
    <Stack key={list.title}>
      <Flex align="center" justify="space-between">
        <Text as="h4" fontWeight="bold" fontSize="md">
          {list.title}
        </Text>
        {isUpdating ? (
          <Spinner />
        ) : (
          <Text fontWeight="semibold" fontSize="md">
            {`${finished.length}/${list.items.length}`}
          </Text>
        )}
      </Flex>
      <Progress
        colorScheme="teal"
        size="xs"
        value={finished.length}
        max={list.items.length}
      />
      <List spacing={4}>
        {list.items.map((item) => {
          const key = getMapKey(list.title, item);
          return (
            <ChecklistItem
              transaction={transaction}
              shiftKeyRef={shiftKey}
              mapKey={key}
              key={key}
              onToggle={updateTransaction}
            >
              {item}
            </ChecklistItem>
          );
        })}
      </List>
    </Stack>
  );
}

Checklist.propTypes = {
  checklistsMap: PropTypes.object,
  list: PropTypes.shape({
    items: PropTypes.array,
    title: PropTypes.string,
  }),
  shiftKey: PropTypes.object,
  transaction: PropTypes.any,
};

function Checklists({ transaction }) {
  const { checklists, checklistsMap = {} } = transaction.exists
    ? transaction.data()
    : {};
  const shiftKey = useRef(false);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key === 'Shift') {
        shiftKey.current = true;
      }
    };

    const handleKeyup = (event) => {
      if (event.key === 'Shift') {
        shiftKey.current = false;
      }
    };
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  }, []);

  return (
    <SimpleGrid columns={[1, 1, 1, 1, 2, 4]} spacing={8} p={4}>
      {checklists.map((list) => {
        return (
          <Checklist
            shiftKey={shiftKey}
            key={list.title}
            list={list}
            checklistsMap={checklistsMap}
            transaction={transaction}
          />
        );
      })}
    </SimpleGrid>
  );
}

Checklists.propTypes = {
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }),
};

function ChecklistsContainer({ transaction }) {
  return transaction.exists ? (
    <Checklists transaction={transaction} />
  ) : (
    <Spinner />
  );
}

ChecklistsContainer.propTypes = {
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.bool,
  }),
};

export default ChecklistsContainer;

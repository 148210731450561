import PropTypes from 'prop-types';
import {
  IconButton,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Spinner,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  Icon,
  Box,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { VscSplitHorizontal } from 'react-icons/vsc';
import Panel, { PanelBody, PanelHeader, PanelNav } from '@common/Panel';
import Header from './Header';
import Property from './Property';
import Parties from './Parties';
import Dates from './Dates';
import Costs from './Costs';
import Financing from './Financing';
import Exhibits from './Exhibits';
import Checklists from './Checklists';
import Tasks from './Tasks';
import Notes from './Notes';
import Timeline from './Timeline';

function View(props) {
  const sidePanelDisclosure = useDisclosure({ defaultIsOpen: true });
  const mobileSidePanelDisclosure = useDisclosure({ defaultIsOpen: false });

  return (
    <>
      <Panel flexGrow="2" layerStyle="canvas">
        <Header
          {...props}
          actions={
            <IconButton
              display={[
                mobileSidePanelDisclosure.isOpen ? 'none' : 'block',
                null,
                null,
                sidePanelDisclosure.isOpen ? 'none' : 'block',
              ]}
              icon={<Icon as={VscSplitHorizontal} />}
              onClick={() => {
                sidePanelDisclosure.onOpen();
                mobileSidePanelDisclosure.onOpen();
              }}
            />
          }
        />
        <PanelBody p={0}>
          <Tabs
            w="100%"
            size="sm"
            colorScheme="teal"
            variant="solid-rounded"
            defaultIndex={
              window.location.hash.substr(1) === ''
                ? 0
                : Number(window.location.hash.substr(1))
            }
            onChange={(index) => (window.location.hash = '#' + index)}
          >
            <PanelNav>
              <TabList>
                <Tab>Overview</Tab>
                <Tab>Details</Tab>
                <Tab>Checklists</Tab>
              </TabList>
            </PanelNav>
            <TabPanels>
              <TabPanel>
                <Box mb={4}>
                  <Property {...props} />
                </Box>
                <SimpleGrid columns={[1, null, null, 2]} spacing={4}>
                  <Parties {...props} />
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <SimpleGrid columns={[1, null, null, 2]} spacing={4}>
                  <Dates {...props} />
                  <Costs {...props} />
                  <Financing {...props} />
                  <Exhibits {...props} />
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <Checklists {...props} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PanelBody>
      </Panel>
      <Panel
        position={{ base: 'absolute', md: 'static' }}
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex={{ base: 'docked', md: 'auto' }}
        flexGrow="1"
        borderLeftWidth="1px"
        display={[
          mobileSidePanelDisclosure.isOpen ? 'block' : 'none',
          null,
          null,
          sidePanelDisclosure.isOpen ? 'block' : 'none',
        ]}
      >
        <PanelHeader
          actions={
            <IconButton
              variant="ghost"
              icon={<CloseIcon />}
              onClick={() => {
                sidePanelDisclosure.onClose();
                mobileSidePanelDisclosure.onClose();
              }}
              label="Hide Side Panel"
            />
          }
        >
          &nbsp;
        </PanelHeader>
        <PanelBody p={0} centerContent={props.isLoading}>
          {props.isLoading ? (
            <Center pt={12}>
              <Spinner />
            </Center>
          ) : (
            <Accordion
              reduceMotion={true}
              defaultIndex={[0, 1, 2]}
              allowMultiple
            >
              <AccordionItem borderTopWidth={0}>
                <AccordionButton fontWeight="semibold" py={4}>
                  Tasks
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px={0} pt={0}>
                  <Tasks {...props} />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton fontWeight="semibold" py={4}>
                  Notes
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Notes {...props} />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton fontWeight="semibold" py={4}>
                  Timeline
                  <Spacer />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Timeline {...props} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </PanelBody>
      </Panel>
    </>
  );
}

View.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  transaction: PropTypes.shape({
    data: PropTypes.func,
  }),
};

export default View;

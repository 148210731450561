const { Container } = require('@chakra-ui/react');

function PanelNav(props) {
  return (
    <Container
      borderBottom="1px"
      layerStyle="panel"
      position="sticky"
      zIndex={1}
      h="auto"
      p={2}
      top="56px" // panel header height
      maxW="100%"
      {...props}
    />
  );
}

export default PanelNav;

import PropTypes from 'prop-types';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useCollaboratorsList } from '../../resources/collaborators';
import { useTransactionHistory } from '../../resources/transactions';
import { formatDate } from '@common/utils/date';

function History({ isOpen, onClose, transaction }) {
  const collaborators = useCollaboratorsList();
  const [history, isLoading] = useTransactionHistory(
    transaction.ref.collection('history').orderBy('when', 'desc')
  );
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>History for {transaction.data().name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>User</Th>
                  <Th>Change</Th>
                </Tr>
              </Thead>
              <Tbody>
                {history.docs.map((item) => {
                  const updater = collaborators.find(
                    (c) => c.uid === item.data().userId
                  );
                  return (
                    <Tr key={item.id}>
                      <Td>
                        {formatDate(item.data().when, 'MM/dd/yyyy h:mm a')}
                      </Td>
                      <Td>{updater ? updater.displayName : '???'}</Td>
                      <Td> {item.data().change}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

History.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    data: PropTypes.func,
    ref: PropTypes.shape({
      collection: PropTypes.func,
    }),
  }).isRequired,
};

export default History;

import PropTypes from 'prop-types';
import { Container } from '@chakra-ui/react';

function PanelBody({ children, ...props }) {
  return (
    <Container maxW="100%" flex={1} p={4} {...props}>
      {children}
    </Container>
  );
}

PanelBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelBody;

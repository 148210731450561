import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  AvatarGroup,
  Avatar,
  MenuItem,
  MenuDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  IconButton,
  Stack,
  Box,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  ChevronDownIcon,
  CloseIcon,
  SmallCloseIcon,
  TimeIcon,
} from '@chakra-ui/icons';
import { VscSettings, VscSymbolEvent } from 'react-icons/vsc';
import addDays from 'date-fns/addDays';
import { useLocation } from 'react-router-dom';
import { tasksCollection } from '../resources/tasks';
import { TODAY, TOMORROW } from '@common/utils/date';
import { useCollaboratorsList } from '../resources/collaborators';

const FilterButton = React.forwardRef(function FilterButton(
  { isSelected, isPressed, ...props },
  ref
) {
  return (
    <Button
      minW="100%"
      size="sm"
      variant="outline"
      layerStyle="selected"
      textAlign="left"
      display="flex"
      justifyContent="flex-start"
      ref={ref}
      aria-selected={isSelected}
      aria-pressed={isPressed}
      {...props}
    />
  );
});

FilterButton.propTypes = {
  isPressed: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export function useFilters() {
  const location = useLocation();
  const [filters, setFilters] = useState(new URLSearchParams(location.search));
  const qs = filters.toString();
  useEffect(() => {
    window.history.replaceState(
      {},
      '',
      `${location.pathname}${qs ? '?' : ''}${qs}`
    );
  }, [location.pathname, qs]);
  return [filters, setFilters];
}

export function filterCollection(filters, limit) {
  let collection = tasksCollection;

  if (filters.has('assignee')) {
    if (filters.get('assignee') === 'unassigned') {
    } else {
      const assignees = filters.get('assignee').split(',');
      collection = collection.where('assignee', 'in', assignees);
    }
  }

  if (filters.has('startDate')) {
    if (filters.get('startDate') === 'Overdue') {
      collection = collection.where('startDate', '<', TODAY);
    }

    if (filters.get('startDate') === 'Today') {
      collection = collection.where('startDate', '==', TODAY);
    }

    if (filters.get('startDate') === 'Tomorrow') {
      collection = collection.where('startDate', '==', TOMORROW);
    }

    if (filters.get('startDate') === 'Next 7 Days') {
      collection = collection
        .where('startDate', '>=', TODAY)
        .where('startDate', '<=', addDays(TODAY, 7));
    }

    if (filters.get('startDate') === 'Next 30 Days') {
      collection = collection
        .where('startDate', '>=', TODAY)
        .where('startDate', '<=', addDays(TODAY, 30));
    }
  }

  if (filters.has('status')) {
    collection = collection.where(
      'isComplete',
      '==',
      filters.get('status') === 'Complete' ? true : false
    );
  }
  if (filters.has('isUrgent')) {
    collection = collection.where('isUrgent', '==', true);
  }

  if (filters.has('isArchived')) {
    collection = collection.where('isArchived', '==', !!filters.isArchived);
  }

  const sorts = {
    newest: ['createdAt', 'desc'],
    oldest: ['createdAt', 'asc'],
    startDate: ['startDate', 'asc'],
  };

  const orderBy = sorts[filters.get('sortBy')] || sorts.newest;

  return collection.orderBy(...orderBy).limit(limit);
}

function Filters({ filters, onFilterToggle, ...props }) {
  const collaborators = useCollaboratorsList();

  const handleFilterToggle = (filter) => {
    return onFilterToggle(new URLSearchParams(filter.toString()));
  };

  return (
    <Stack shouldWrapChildren {...props}>
      <Button
        size="xs"
        variant="outline"
        disabled={filters.toString().length === 0}
        _disabled={{
          opacity: 0,
        }}
        onClick={() => {
          onFilterToggle(new URLSearchParams());
        }}
        leftIcon={<SmallCloseIcon />}
      >
        Clear filters
      </Button>
      <Menu isLazy>
        <MenuButton
          as={FilterButton}
          isSelected={filters.has('assignee')}
          rightIcon={<ChevronDownIcon />}
        >
          {filters.has('assignee') ? (
            <AvatarGroup>
              {filters
                .get('assignee')
                .split(',')
                .map((assignee) => {
                  const collab = collaborators.find((c) => {
                    return assignee === c.uid;
                  });
                  if (collab) {
                    return (
                      <Avatar size="xs" key={assignee} src={collab.photoURL} />
                    );
                  }

                  return <Avatar size="xs" key="unassigned" />;
                })}
            </AvatarGroup>
          ) : (
            'Assignee'
          )}
        </MenuButton>
        <MenuList>
          <MenuItemOption
            isChecked={filters.get('assignee') === 'unassigned'}
            onClick={() => {
              filters.set('assignee', 'unassigned');
              handleFilterToggle(filters);
            }}
          >
            Unassigned
          </MenuItemOption>
          <MenuDivider />
          <MenuOptionGroup
            type="checkbox"
            title="Assigned to"
            onChange={(assignee) => {
              filters.set('assignee', assignee);
              handleFilterToggle(filters);
            }}
            value={
              filters.get('assignee') ? filters.get('assignee').split(',') : []
            }
          >
            {collaborators.map((collaborator) => (
              <MenuItemOption value={collaborator.uid} key={collaborator.uid}>
                <Avatar
                  size="xs"
                  key={collaborator}
                  src={collaborator.photoURL}
                />{' '}
                {collaborator.shortName}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          {filters.has('assignee') && (
            <>
              <MenuDivider />
              <MenuItem
                icon={<CloseIcon />}
                onClick={() => {
                  filters.delete('assignee');
                  handleFilterToggle(filters);
                }}
              >
                Clear
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
      <Menu isLazy>
        <MenuButton
          as={FilterButton}
          isSelected={filters.has('startDate')}
          leftIcon={<CalendarIcon />}
          rightIcon={<ChevronDownIcon />}
        >
          {filters.get('startDate') || 'Start'}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type="radio"
            title="Start Date"
            onChange={(startDate) => {
              filters.set('startDate', startDate);
              handleFilterToggle(filters);
            }}
            value={filters.get('startDate') || ''}
          >
            <MenuItemOption value="Overdue">Overdue</MenuItemOption>
            <MenuItemOption value="Today">Today</MenuItemOption>
            <MenuItemOption value="Tomorrow">Tomorrow</MenuItemOption>
            <MenuItemOption value="Next 7 Days">Next 7 Days</MenuItemOption>
            <MenuItemOption value="Next 30 Days">Next 30 Days</MenuItemOption>
          </MenuOptionGroup>
          {filters.has('startDate') && (
            <>
              <MenuDivider />
              <MenuItem
                icon={<CloseIcon />}
                onClick={() => {
                  filters.delete('startDate');
                  handleFilterToggle(filters);
                }}
              >
                Clear
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
      <Menu isLazy>
        <MenuButton
          as={FilterButton}
          isSelected={filters.has('status')}
          rightIcon={<ChevronDownIcon />}
        >
          {!filters.has('status') ? 'Status' : filters.get('status')}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type="radio"
            onChange={(status) => {
              filters.set('status', status);
              handleFilterToggle(filters);
            }}
            value={filters.get('status') || ''}
          >
            <MenuItemOption value="Complete">Complete</MenuItemOption>
            <MenuItemOption value="Incomplete">Incomplete</MenuItemOption>
          </MenuOptionGroup>
          {filters.has('status') && (
            <>
              <MenuDivider />
              <MenuItem
                icon={<CloseIcon />}
                onClick={() => {
                  filters.delete('status');
                  handleFilterToggle(filters);
                }}
              >
                Clear
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>

      <FilterButton
        isPressed={filters.has('isUrgent')}
        onClick={() => {
          if (filters.has('isUrgent')) {
            filters.delete('isUrgent');
          } else {
            filters.set('isUrgent', true);
          }
          handleFilterToggle(filters);
        }}
        leftIcon={<VscSymbolEvent />}
        minW="100%"
      >
        Urgent
      </FilterButton>
      <FilterButton
        isPressed={filters.has('isArchived')}
        onClick={() => {
          if (filters.has('isArchived')) {
            filters.delete('isArchived');
          } else {
            filters.set('isArchived', true);
          }
          handleFilterToggle(filters);
        }}
        leftIcon={<TimeIcon />}
        minW="100%"
      >
        Archived
      </FilterButton>
      <Menu isLazy>
        <MenuButton
          as={FilterButton}
          isSelected={filters.has('status')}
          rightIcon={<ChevronDownIcon />}
        >
          Sort by {filters.has('sortBy') ? filters.get('sortBy') : 'newest'}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type="radio"
            onChange={(sortBy) => {
              filters.set('sortBy', sortBy);
              handleFilterToggle(filters);
            }}
            value={filters.get('sortBy') || 'newest'}
          >
            <MenuItemOption value="newest">Newest</MenuItemOption>
            <MenuItemOption value="oldest">Oldest</MenuItemOption>
            <MenuItemOption value="startDate">Start Date</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Stack>
  );
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  onFilterToggle: PropTypes.func.isRequired,
};

function FiltersContainer({ filters, onFilterToggle }) {
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <IconButton
            size="sm"
            variant="outline"
            icon={<VscSettings />}
            display={{ base: 'flex', lg: 'none' }}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Filters
              filters={filters}
              onFilterToggle={onFilterToggle}
              justifyContent="stretch"
              alignItems="stretch"
              direction="column-reverse"
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Box display={{ base: 'none', lg: 'flex' }}>
        <Filters
          filters={filters}
          onFilterToggle={onFilterToggle}
          alignItems="center"
          direction="row"
        />
      </Box>
    </>
  );
}

FiltersContainer.propTypes = {
  filters: PropTypes.shape({
    assignee: PropTypes.array,
    isArchived: PropTypes.any,
    isComplete: PropTypes.any,
    isUrgent: PropTypes.any,
    startDate: PropTypes.any,
  }),
  onFilterToggle: PropTypes.func.isRequired,
};

export default FiltersContainer;

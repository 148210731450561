import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';
import { getDateFromString, TODAY, TOMORROW } from '@common/utils/date';
import { TIMELINE, SUGGESTIONS, TITLES } from '../../constants/timelineDates';

export default function getDateSuggestions(baseDates, str = '') {
  const numFromString = parseInt(str.trim());
  const dateFromString = getDateFromString(str.trim());
  const isNumeric = !isNaN(numFromString) && !str.match(/\/|-/g);
  const suggestions = [];

  suggestions.push({
    label: 'Today',
    value: TODAY,
  });
  suggestions.push({
    label: 'Tomorrow',
    value: TOMORROW,
  });

  let exactMatch = false;
  SUGGESTIONS.forEach((key) => {
    if (baseDates[key]) {
      if (isNumeric) {
        // Don't show relative date before binding
        if (key !== TIMELINE.BINDING) {
          suggestions.push({
            label: `${numFromString} ${
              numFromString > 1 ? 'days' : 'day'
            } before ${TITLES[key].toLowerCase()}`,
            value: subDays(baseDates[key], numFromString),
            base: key,
          });
        }

        suggestions.push({
          label: `${numFromString} ${
            numFromString > 1 ? 'days' : 'day'
          } after ${TITLES[key].toLowerCase()}`,
          value: addDays(baseDates[key], numFromString),
          base: key,
        });
      } else {
        if (isSameDay(baseDates[key], dateFromString)) {
          exactMatch = true;
        }
        suggestions.push({
          label: TITLES[key],
          value: baseDates[key],
          base: key,
        });
      }
    }
  });

  if (isValid(dateFromString) && !exactMatch) {
    suggestions.unshift({
      label: format(dateFromString, 'MMM d'),
      value: dateFromString,
      _fromString: true,
    });
  }

  return suggestions.filter((s) => {
    return (
      s._fromString ||
      isSameDay(s.value, dateFromString) ||
      s.label.toLowerCase().includes(str)
    );
  });
}

import PropTypes from 'prop-types';
import { Button, IconButton, useClipboard } from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';

function CopyButton({ text, icon = <CopyIcon />, ...props }) {
  const { hasCopied, onCopy } = useClipboard(text);
  if (props.children) {
    return (
      <Button
        onClick={onCopy}
        variant="link"
        textAlign="left"
        justifyContent="flex-start"
        rightIcon={hasCopied ? <CheckIcon /> : icon}
        size="xs"
        {...props}
      />
    );
  }

  return (
    <IconButton
      onClick={onCopy}
      icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
      aria-label="Copy to clipboard"
      variant="ghost"
      size="xs"
      {...props}
    />
  );
}

CopyButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
};

export default CopyButton;

import PropTypes from 'prop-types';
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  SimpleGrid,
  Skeleton,
  Link,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Switch, Route, Link as RouterLink } from 'react-router-dom';
import addMonths from 'date-fns/addMonths';
import { Helmet } from 'react-helmet';
import Section from '@common/Section';
import TransactionsTable from '@common/TransactionsTable';
import LinkButton from '@common/LinkButton';
import { TODAY, THIRTY_DAYS_FROM_NOW, formatDate } from '@common/utils/date';
import Panel, { PanelHeader, PanelBody } from '@common/Panel';
import {
  transactionsCollection,
  useTransactionStats,
} from '../resources/transactions';

function Recent({ limit = 25 }) {
  return (
    <TransactionsTable
      collection={transactionsCollection
        .orderBy('createdAt', 'desc')
        .limit(limit)}
      columns={{
        name: 'Name',
        status: 'Status',
        createdAt: 'Created',
        agent: 'Agent',
      }}
    />
  );
}

Recent.propTypes = {
  action: PropTypes.node,
  limit: PropTypes.number,
};

function RecentlyUpdated({ limit = 25 }) {
  return (
    <TransactionsTable
      collection={transactionsCollection
        .orderBy('updatedAt', 'desc')
        .limit(limit)}
      columns={{
        name: 'Name',
        status: 'Status',
        updatedAt: 'Updated',
        agent: 'Agent',
      }}
    />
  );
}

RecentlyUpdated.propTypes = {
  action: PropTypes.node,
  limit: PropTypes.number,
};

function ClosingSoon({ limit = 99999 }) {
  return (
    <TransactionsTable
      collection={transactionsCollection
        .where('closingDate', '>=', TODAY)
        .where('closingDate', '<=', THIRTY_DAYS_FROM_NOW)
        .orderBy('closingDate', 'asc')
        .limit(limit)}
      columns={{
        name: 'Name',
        closingDate: 'Closing',
        agent: 'Agent',
      }}
    />
  );
}

ClosingSoon.propTypes = {
  action: PropTypes.node,
  limit: PropTypes.number,
};

function Unpaid({ limit = 25 }) {
  return (
    <TransactionsTable
      collection={transactionsCollection
        .where('status', '!=', 'ACTIVE')
        .where('paymentStatus', '==', 'PENDING')
        .orderBy('status', 'asc')
        .orderBy('closingDate', 'asc')
        .limit(limit)}
      columns={{
        name: 'Name',
        status: 'Status',
        closingDate: 'Closing',
        agent: 'Agent',
      }}
    />
  );
}

Unpaid.propTypes = {
  action: PropTypes.node,
  limit: PropTypes.number,
};

function Dashboard() {
  const [stats = {}, isLoading] = useTransactionStats();
  return (
    <>
      <Box mb={4}>
        <Section>
          <StatGroup p={4} justifyContent="space-evenly">
            <Stat flex={0}>
              <StatLabel whiteSpace="nowrap">Active Transactions</StatLabel>
              <StatNumber>
                <Skeleton minH="1em" isLoaded={!isLoading}>
                  {stats.activeTransactions}
                </Skeleton>
              </StatNumber>
            </Stat>
            <Stat flex={0}>
              <StatLabel whiteSpace="nowrap">
                Closings in {formatDate(TODAY, 'MMMM')}
              </StatLabel>

              <StatNumber>
                <Skeleton minH="1em" isLoaded={!isLoading}>
                  {stats.closingsThisMonth}
                </Skeleton>
              </StatNumber>
            </Stat>
            <Stat flex={0}>
              <StatLabel whiteSpace="nowrap">
                Closings in {formatDate(addMonths(TODAY, 1), 'MMMM')}
              </StatLabel>
              <StatNumber>
                <Skeleton minH="1em" isLoaded={!isLoading}>
                  {stats.closingsNextMonth}
                </Skeleton>
              </StatNumber>
            </Stat>
          </StatGroup>
        </Section>
      </Box>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
        <Section
          title="Recent"
          action={
            <Link
              fontWeight="semibold"
              fontSize="sm"
              as={RouterLink}
              to="/dashboard/recent"
            >
              View All
            </Link>
          }
        >
          <Recent limit={5} />
        </Section>

        <Section
          title="Recently Updated"
          action={
            <Link
              fontWeight="semibold"
              fontSize="sm"
              as={RouterLink}
              to="/dashboard/recently-updated"
            >
              View All
            </Link>
          }
        >
          <RecentlyUpdated limit={5} />
        </Section>

        <Section
          title="Closing Soon"
          action={
            <Link
              fontWeight="semibold"
              fontSize="sm"
              as={RouterLink}
              to="/dashboard/closing-soon"
            >
              View All
            </Link>
          }
        >
          <ClosingSoon limit={5} />
        </Section>

        <Section
          title="Unpaid"
          action={
            <Link
              fontWeight="semibold"
              fontSize="sm"
              as={RouterLink}
              to="/dashboard/unpaid"
            >
              View All
            </Link>
          }
        >
          <Unpaid limit={5} />
        </Section>
      </SimpleGrid>
    </>
  );
}

function Transactions() {
  return (
    <Panel layerStyle="canvas">
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={() => {
            return (
              <>
                <Helmet>
                  <title>Dashboard - Closing Bot</title>
                </Helmet>
                <PanelHeader>Dashboard</PanelHeader>
                <PanelBody>
                  <Dashboard />
                </PanelBody>
              </>
            );
          }}
        />
        <Route
          path="/dashboard/recent"
          render={() => {
            return (
              <>
                <Helmet>
                  <title>Recent Transactions - Closing Bot</title>
                </Helmet>
                <PanelHeader
                  actions={
                    <RouterLink
                      component={LinkButton}
                      variant="link"
                      to="/dashboard"
                      leftIcon={<ChevronLeftIcon />}
                    >
                      Dashboard
                    </RouterLink>
                  }
                >
                  Recent Transactions
                </PanelHeader>
                <PanelBody p={0}>
                  <Recent />
                </PanelBody>
              </>
            );
          }}
        />
        <Route
          path="/dashboard/recently-updated"
          render={() => {
            return (
              <>
                <Helmet>
                  <title>Recently Updated Transactions - Closing Bot</title>
                </Helmet>
                <PanelHeader
                  actions={
                    <RouterLink
                      component={LinkButton}
                      variant="link"
                      to="/dashboard"
                      leftIcon={<ChevronLeftIcon />}
                    >
                      Dashboard
                    </RouterLink>
                  }
                >
                  Recently Updated Transactions
                </PanelHeader>
                <PanelBody p={0}>
                  <Recent />
                </PanelBody>
              </>
            );
          }}
        />
        <Route
          path="/dashboard/closing-soon"
          render={() => {
            return (
              <>
                <Helmet>
                  <title>Closing Soon - Closing Bot</title>
                </Helmet>
                <PanelHeader
                  actions={
                    <RouterLink
                      component={LinkButton}
                      variant="link"
                      to="/dashboard"
                      leftIcon={<ChevronLeftIcon />}
                    >
                      Dashboard
                    </RouterLink>
                  }
                >
                  Closing Soon
                </PanelHeader>
                <PanelBody p={0}>
                  <Recent />
                </PanelBody>
              </>
            );
          }}
        />
        <Route
          path="/dashboard/unpaid"
          render={() => {
            return (
              <>
                <Helmet>
                  <title>Unpaid Transactions - Closing Bot</title>
                </Helmet>

                <PanelHeader
                  actions={
                    <RouterLink
                      component={LinkButton}
                      variant="link"
                      to="/dashboard"
                      leftIcon={<ChevronLeftIcon />}
                    >
                      Dashboard
                    </RouterLink>
                  }
                >
                  Unpaid
                </PanelHeader>
                <PanelBody p={0}>
                  <Recent />
                </PanelBody>
              </>
            );
          }}
        />
      </Switch>
    </Panel>
  );
}

export default Transactions;

import PropTypes from 'prop-types';
import { SimpleGrid } from '@chakra-ui/react';
import counties from '../../constants/counties.json';
import { Fieldset } from '@common/FormField';
import { Text, Select } from './Fields';

function Property({ transaction }) {
  return (
    <Fieldset legend="Property">
      <Text label="Address" name="address" document={transaction} />
      <SimpleGrid columns={[1, null, null, 3]} spacing={4}>
        <Select
          label="County"
          name="county"
          document={transaction}
          placeholder="Select a county"
        >
          {counties.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        <Text label="Short Name" name="shortName" document={transaction} />
        <Text label="FMLS" name="fmls" document={transaction} />
      </SimpleGrid>
    </Fieldset>
  );
}

Property.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default Property;

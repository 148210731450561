import { useReducer } from 'react';

function reducer(state, action) {
  return {
    ...state,
    ...action,
  };
}

export default function useDraftDocument(initialState) {
  return useReducer(reducer, initialState);
}

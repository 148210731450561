import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

function DeleteConfirmationModal({
  onConfirm,
  successMessage,
  errorMessage,
  onClose,
  isOpen,
}) {
  const toast = useToast();
  const cancelRef = useRef();
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteClick = () => {
    setIsDeleting(true);
    onConfirm()
      .then(() => {
        toast({
          status: 'success',
          description: successMessage,
        });
        onClose();
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast({
          status: 'error',
          title: 'An error occurred.',
          description: errorMessage,
          isClosable: true,
        });
        onClose();
      });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogBody>Are you sure? You can't undo this.</AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              {!isDeleting && (
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
              )}
              <Button
                colorScheme="red"
                onClick={handleDeleteClick}
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

DeleteConfirmationModal.propTypes = {
  errorMessage: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
};

export default DeleteConfirmationModal;

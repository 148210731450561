import { BrowserRouter as Router } from 'react-router-dom';
import {
  ChakraProvider,
  CSSReset,
  Center,
  createStandaloneToast,
} from '@chakra-ui/react';
import Main from './Main';
import Loading from '@common/Loading';
import { TOMORROW } from '@common/utils/date';
import { useCurrentUser } from './resources/auth';
import {
  useCollaborators,
  CollaboratorsProvider,
} from './resources/collaborators';
import theme from './theme';
import TimezoneCheck from '@common/TimezoneCheck';

let currentDayTimer = window.setInterval(() => {
  const now = Date.now();
  if (now > TOMORROW.getTime()) {
    window.clearInterval(currentDayTimer);
    const toast = createStandaloneToast();
    toast({
      title: 'This page is out of date.',
      description:
        'Refresh now or it will automatically refresh in 10 seconds.',
      status: 'warning',
      duration: 10000,
      position: 'top',
      onCloseComplete: () => {
        window.location.reload();
      },
    });
  }
}, 30000); // 30 sec

function App() {
  const [currentUser, isInitializing, error] = useCurrentUser();
  const [
    collaborators,
    isLoadingCollaborators,
    collaboratorsError,
  ] = useCollaborators();

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />

      {isInitializing || isLoadingCollaborators ? (
        <Loading />
      ) : error || collaboratorsError ? (
        <Center w="100vw" h="100vh">
          <h1>There was a problem logging you in.</h1>
          <p>{error}</p>
        </Center>
      ) : (
        <Router>
          <TimezoneCheck
            timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
          <CollaboratorsProvider value={collaborators}>
            <Main currentUser={currentUser} />
          </CollaboratorsProvider>
        </Router>
      )}
    </ChakraProvider>
  );
}

export default App;

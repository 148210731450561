import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import DateInputPopup from './DateInputPopup';
import './DateInput.css';
import ErrorBoundary from '../ErrorBoundary';
import { normalizeTimezone } from '@common/utils/date';

function DateInput({
  size = 'sm',
  variant = 'ghost',
  baseDates = {},
  label,
  value,
  onChange,
  ...props
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const inputRef = useRef();
  const triggerText =
    value && isValid(value) ? format(normalizeTimezone(value), 'M/d') : label;

  // Wrapping in empty div prevents the margin from stacks from affecting it
  return (
    <div>
      <Popover
        isLazy
        gutter={0}
        placement="left"
        onOpen={onOpen}
        onClose={onClose}
        initialFocusRef={inputRef}
        isOpen={isOpen}
      >
        <PopoverTrigger>
          <Button
            className={!triggerText && 'emptyDateInputTrigger'}
            size={size}
            leftIcon={<CalendarIcon />}
            variant={variant}
            fontWeight="normal"
            {...props}
          >
            {triggerText}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <ErrorBoundary>
              {isOpen && (
                <DateInputPopup
                  inputRef={inputRef}
                  baseDates={baseDates}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onClose();
                  }}
                />
              )}
            </ErrorBoundary>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
}

DateInput.propTypes = {
  baseDates: PropTypes.object,
  children: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  value: PropTypes.object,
  variant: PropTypes.string,
};

export default DateInput;

import PropTypes from 'prop-types';
import { Container } from '@chakra-ui/react';

function Panel({ children, ...props }) {
  return (
    <Container
      flex={1}
      p={0}
      maxW="100%"
      h="100vh"
      overflowY="scroll"
      flexDirection="column"
      position="relative"
      {...props}
    >
      {children}
    </Container>
  );
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Panel;

import PropTypes from 'prop-types';
import Autocomplete from '@common/Autocomplete';
import { agentsCollection, useAgents } from '../resources/agents';

function AgentSelect({ value, onChange }) {
  const [agents, isLoading, error] = useAgents(
    agentsCollection.orderBy('name', 'asc')
  );

  if (error) {
    throw new Error(error);
  }

  const options = isLoading
    ? []
    : agents.docs.map((agent) => {
        return {
          value: agent.id,
          label: agent.data().name,
          agent,
        };
      });
  return (
    <Autocomplete
      isLoading={isLoading}
      placeholder="Select an agent"
      options={options}
      value={options.find((o) => o.value === value)}
      onChange={onChange}
    />
  );
}

AgentSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default AgentSelect;

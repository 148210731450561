import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Editable,
  EditablePreview,
  EditableInput,
  Stack,
  FormControl,
  FormLabel,
  Divider,
  Button,
  Heading,
  SimpleGrid,
  IconButton,
  VisuallyHidden,
  HStack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import Panel, { PanelHeader, PanelBody } from '@common/Panel';
import Section from '@common/Section';
import Loading from '@common/Loading';
import TransactionsTable from '@common/TransactionsTable';
import ErrorBoundary from '@common/ErrorBoundary';
import { DEFAULT_CHECKLISTS } from '../constants/checklists';
import { FieldValue } from '../resources/firebase';
import { useAgent, updateAgent } from '../resources/agents';
import { transactionsCollection } from '../resources/transactions';

function EditableChecklistItem({ item, onSubmit, onRemove }) {
  return (
    <FormControl className="cb-revealable">
      <FormLabel>
        <VisuallyHidden>Checklist Item</VisuallyHidden>
      </FormLabel>
      <Editable
        defaultValue={item}
        selectAllOnFocus={false}
        onSubmit={onSubmit}
      >
        {({ isEditing }) => (
          <HStack>
            <EditableInput />
            <EditablePreview />
            {!isEditing && (
              <IconButton
                icon={<DeleteIcon />}
                size="sm"
                onClick={onRemove}
                className="cb-revealable-item"
              />
            )}
          </HStack>
        )}
      </Editable>
    </FormControl>
  );
}

EditableChecklistItem.propTypes = {
  item: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function NewChecklistItem({ onSubmit }) {
  const [draftItem, setDraftItem] = useState('');
  return (
    <FormControl>
      <FormLabel>
        <VisuallyHidden>Checklist Item</VisuallyHidden>
      </FormLabel>
      <Editable
        onSubmit={(value) => {
          if (value.length > 0) {
            onSubmit(value);
          }
          setDraftItem('');
        }}
        onCancel={() => setDraftItem('')}
        onChange={setDraftItem}
        value={draftItem}
        selectAllOnFocus={false}
      >
        {({ isEditing, onEdit }) => (
          <HStack>
            <EditableInput />
            {!isEditing && (
              <Button leftIcon={<AddIcon />} size="sm" onClick={onEdit}>
                Add
              </Button>
            )}
          </HStack>
        )}
      </Editable>
    </FormControl>
  );
}

NewChecklistItem.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function Agent({ agent }) {
  const agentData = agent.data();
  const { checklists = {} } = agentData;

  return (
    <Panel layerStyle="canvas">
      <PanelHeader>
        <Editable
          defaultValue={agentData.name}
          selectAllOnFocus={false}
          onSubmit={(changed) => {
            updateAgent(agent, { name: changed });
          }}
        >
          <EditableInput />
          <EditablePreview />
        </Editable>
      </PanelHeader>
      <PanelBody>
        <Stack direction={{ base: 'column-reverse', sm: 'row' }}>
          <Stack flex={2}>
            <Section title="Checklists">
              <SimpleGrid columns={[1, 1, 1, 1, 2, 4]} spacing={8} p={4}>
                {DEFAULT_CHECKLISTS.map((list) => {
                  const items = checklists[list.title]
                    ? checklists[list.title]
                    : [];
                  return (
                    <div key={list.title}>
                      <Heading size="sm">{list.title}</Heading>
                      <UnorderedList>
                        {items.map((item, index) => {
                          return (
                            <ListItem key={item}>
                              <EditableChecklistItem
                                item={item}
                                onSubmit={(value) => {
                                  const nextItems = [...items];
                                  nextItems[index] = value;
                                  updateAgent(agent, {
                                    [`checklists.${list.title}`]: nextItems,
                                  });
                                }}
                                onRemove={() => {
                                  // TODO: move this to be its own update function
                                  updateAgent(agent, {
                                    [`checklists.${list.title}`]: FieldValue.arrayRemove(
                                      item
                                    ),
                                  });
                                }}
                              />
                            </ListItem>
                          );
                        })}
                      </UnorderedList>
                      <NewChecklistItem
                        onSubmit={(item) => {
                          updateAgent(agent, {
                            [`checklists.${list.title}`]: FieldValue.arrayUnion(
                              item
                            ),
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </SimpleGrid>
            </Section>
            <Section title="Active Transactions">
              <TransactionsTable
                emptyState="No active transactions."
                collection={transactionsCollection
                  .where('agent', '==', agent.ref)
                  .where('status', '==', 'ACTIVE')
                  .orderBy('createdAt', 'desc')}
                columns={{
                  name: 'Name',
                  closingDate: 'Closing Date',
                  createdAt: 'Created',
                }}
              />
            </Section>
            <Section title="Unpaid Transactions">
              <TransactionsTable
                emptyState="No unpaid transactions!"
                collection={transactionsCollection
                  .where('agent', '==', agent.ref)
                  .where('status', '!=', 'ACTIVE')
                  .where('paymentStatus', '==', 'PENDING')
                  .orderBy('status', 'asc')
                  .orderBy('closingDate', 'asc')}
                columns={{
                  name: 'Name',
                  status: 'Status',
                  closingDate: 'Closing Date',
                  createdAt: 'Created',
                }}
              />
            </Section>
            <Section title="Completed Transactions">
              <TransactionsTable
                emptyState="No completed transactions"
                collection={transactionsCollection
                  .where('agent', '==', agent.ref)
                  .where('paymentStatus', '==', 'PAID')
                  .orderBy('closingDate', 'asc')}
                columns={{
                  name: 'Name',
                  status: 'Status',
                  closingDate: 'Closing Date',
                  createdAt: 'Created',
                }}
              />
            </Section>
          </Stack>
          <Stack flex={1}>
            <Section>
              <Stack spacing={0} divider={<Divider />}>
                <FormControl p={4} id="agentEmail">
                  <FormLabel>Email</FormLabel>
                  <Editable
                    placeholder="Add e-mail"
                    defaultValue={agentData.email}
                    selectAllOnFocus={false}
                    onSubmit={(changed) => {
                      updateAgent(agent, { email: changed });
                    }}
                  >
                    <EditableInput type="email" />
                    <EditablePreview />
                  </Editable>
                </FormControl>
                <FormControl p={4} id="license">
                  <FormLabel>License</FormLabel>
                  <Editable
                    defaultValue={agentData.license}
                    selectAllOnFocus={false}
                    placeholder="Add license"
                    onSubmit={(changed) => {
                      updateAgent(agent, { license: changed });
                    }}
                  >
                    <EditableInput type="license" />
                    <EditablePreview />
                  </Editable>
                </FormControl>
                <FormControl p={4} id="defaultFee">
                  <FormLabel>Fee</FormLabel>
                  <Editable
                    maxW="80px"
                    display="flex"
                    defaultValue={String(agentData.defaultFee)}
                    selectAllOnFocus={false}
                    onSubmit={(changed) => {
                      updateAgent(agent, { defaultFee: Number(changed) });
                    }}
                  >
                    {({ isEditing }) => (
                      <>
                        $
                        <EditableInput p={0} type="number" />
                        <EditablePreview p={0} />
                      </>
                    )}
                  </Editable>
                </FormControl>
                <FormControl p={4} id="defaultTerminationFee">
                  <FormLabel>Termination Fee</FormLabel>
                  <Editable
                    maxW="80px"
                    display="flex"
                    defaultValue={String(agentData.defaultTerminationFee)}
                    selectAllOnFocus={false}
                    onSubmit={(changed) => {
                      updateAgent(agent, {
                        defaultTerminationFee: Number(changed),
                      });
                    }}
                  >
                    {({ isEditing }) => (
                      <>
                        $
                        <EditableInput p={0} type="number" />
                        <EditablePreview p={0} />
                      </>
                    )}
                  </Editable>
                </FormControl>
              </Stack>
            </Section>
          </Stack>
        </Stack>
      </PanelBody>
    </Panel>
  );
}

Agent.propTypes = {
  agent: PropTypes.shape({
    data: PropTypes.func,
    ref: PropTypes.shape({
      collection: PropTypes.func,
    }),
  }).isRequired,
};

function AgentContainer({ match }) {
  const [agent, isLoading, error] = useAgent(`/agents/${match.params.id}`);
  if (error) {
    throw new Error(`Unable to fetch agent. ${error}`);
  }
  return isLoading ? <Loading /> : <Agent agent={agent} />;
}

AgentContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

function AgentContainerWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <AgentContainer {...props} />
    </ErrorBoundary>
  );
}

export default AgentContainerWithErrorBoundary;

import { useAuthState } from 'react-firebase-hooks/auth';
import firebase, { authenticateWithGoogle } from './firebase';

// TODO: do this more securely
const ADMINS = ['jarrettgossett@gmail.com', 'amanda@closingbot.com'];

export function authenticate(...props) {
  return authenticateWithGoogle(...props);
}

export function logout() {
  window.localStorage.removeItem('collaborators');
  firebase.auth().signOut();
}

export function useCurrentUser() {
  const [currentUser, isInitializing, error] = useAuthState(firebase.auth());
  let user;

  if (currentUser) {
    user = {
      isAdmin: ADMINS.includes(currentUser.email),
      ...currentUser.providerData[0],
      uid: currentUser.uid,
    };
  }

  return [user, isInitializing, error];
}

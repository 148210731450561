import { useRef } from 'react';
import Fuse from 'fuse.js';
import { agentsCollection, useAgents } from '../resources/agents';
import {
  transactionsCollection,
  useTransactions,
} from '../resources/transactions';

export const searchableActions = [
  {
    type: 'create',
    path: 'transactions?new',
    name: 'Create a transaction...',
  },
  {
    type: 'transaction',
    path: 'transactions',
    name: 'Dashboard',
  },
  {
    type: 'tasks',
    path: 'tasks',
    name: 'Tasks',
  },
  {
    type: 'agent',
    path: 'agents',
    name: 'Agents',
  },
];

export function useSearch() {
  const fuseRef = useRef();
  const [agents, isAgentsLoading, agentsError] = useAgents(
    agentsCollection.orderBy('name', 'asc')
  );
  const [
    activeTransactions,
    isTransactionsLoading,
    transactionsError,
  ] = useTransactions(
    transactionsCollection.where('paymentStatus', '==', 'PENDING')
  );

  if (!fuseRef.current) {
    const isLoading = isAgentsLoading || isTransactionsLoading;
    const error = agentsError || transactionsError;
    if (
      isLoading ||
      (activeTransactions && activeTransactions.metadata.fromCache) ||
      (agents && agents.metadata.fromCache)
    ) {
      return [null, true];
    }

    if (error) {
      return [null, false, 'Unable to load search results'];
    }

    const agentsToSearch = agents.docs.map((agent) => {
      return {
        type: 'agent',
        path: agent.ref.path,
        name: agent.data().name,
        email: agent.data().email,
      };
    });

    const transactionsToSearch = activeTransactions.docs.map((transaction) => {
      return {
        type: 'transaction',
        path: transaction.ref.path,
        name: transaction.data().name,
        address: transaction.data().address,
        buyer: transaction.data().buyer,
        seller: transaction.data().seller,
      };
    });

    const collection = [
      ...agentsToSearch,
      ...transactionsToSearch,
      ...searchableActions,
    ];

    fuseRef.current = new Fuse(collection, {
      keys: ['name', 'email', 'address', 'buyer', 'seller'],
    });
  }

  return [fuseRef.current, false];
}

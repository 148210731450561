import firebase from 'firebase/app';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import setYear from 'date-fns/setYear';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import isPast from 'date-fns/isPast';

export const TODAY = startOfToday();
export const TOMORROW = startOfTomorrow();
export const THIRTY_DAYS_FROM_NOW = addDays(TODAY, 30);

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getDateFromString(str = '') {
  const year = new Date(str).getFullYear();
  let date =
    year === 2001 ? setYear(new Date(str), TODAY.getFullYear()) : new Date(str);
  if (!isValid(date) && str.length > 0) {
    const month = MONTH_NAMES.findIndex((name) =>
      name.toLowerCase().startsWith(str.toLowerCase())
    );

    if (month > -1) {
      date = new Date(TODAY.getFullYear(), month, 1);
      if (isPast(date)) {
        date = new Date(TODAY.getFullYear() + 1, month, 1);
      }
    }
  }

  return normalizeTimezone(startOfDay(date));
}

export function normalizeTimezone(date, utcOffsetHrs = 0) {
  const baseTzOffset = utcOffsetHrs * 60;
  const tzOffset = date.getTimezoneOffset();
  const d = new Date(date.valueOf() + (baseTzOffset + tzOffset) * 60 * 1000);
  return d;
}

export function formatDate(timestamp, pattern = 'M/d/yyyy') {
  try {
    let date = timestamp;
    if (timestamp instanceof firebase.firestore.Timestamp) {
      date = timestamp.toDate();
    }
    return format(normalizeTimezone(date), pattern);
  } catch (err) {
    return null;
  }
}

export function parseDate(value) {
  try {
    const year = new Date(value).getFullYear();
    const date =
      year === 2001
        ? setYear(new Date(value), new Date().getFullYear())
        : new Date(value);
    return firebase.firestore.Timestamp.fromDate(date);
  } catch (err) {
    return null;
  }
}

export function calculateDateFromDuration(startDate, days) {
  return addDays(normalizeTimezone(startDate.toDate()), days);
}

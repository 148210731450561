import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createAgent, getInitialAgentState } from '../resources/agents';
import useDraftDocument from '@common/useDraftDocument';

function NewAgent({ isOpen, onClose }) {
  const toast = useToast();
  const ref = useRef();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [newAgent, setNewAgent] = useDraftDocument(getInitialAgentState());
  const isValid = !!newAgent.name;

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      setIsSaving(true);
      const createdAgent = await createAgent(newAgent);
      history.push('/' + createdAgent.path);
    } catch (err) {
      setIsSaving(false);
      Sentry.captureException(err);
      toast({
        status: 'error',
        title: 'An error occurred.',
        description: 'Unable to create agent.',
        isClosable: true,
      });
    }
  };

  return (
    <Modal initialFocusRef={ref} size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSave} autoComplete="off">
          <ModalHeader>New Agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              <FormControl id="newAgentName">
                <FormLabel>Name</FormLabel>
                <Input
                  required
                  ref={ref}
                  value={newAgent.name}
                  onChange={(event) => {
                    setNewAgent({ name: event.target.value });
                  }}
                />
              </FormControl>
              <FormControl id="newAgentEmail">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={newAgent.email}
                  onChange={(event) => {
                    setNewAgent({ email: event.target.value });
                  }}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="teal"
                isLoading={isSaving}
                type="submit"
                isDisabled={!isValid}
              >
                Create agent
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

NewAgent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewAgent;

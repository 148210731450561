import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import {
  Box,
  Stack,
  Icon,
  Divider,
  IconButton,
  useDisclosure,
  Circle,
  useToast,
  Spinner,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Center,
  MenuDivider,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react';
import {
  SearchIcon,
  HamburgerIcon,
  WarningIcon,
  ChevronDownIcon,
  AddIcon,
  ArrowLeftIcon,
  LockIcon,
  MoonIcon,
  SunIcon,
} from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { VscFolder, VscAccount, VscChecklist, VscInbox } from 'react-icons/vsc';
import * as Sentry from '@sentry/react';
import Panel, { PanelBody, PanelHeader } from '@common/Panel';
import useKeyboardShortcut from '@common/useKeyboardShortcut';
import { logout, useCurrentUser } from '../resources/auth';
import QuickSearch from './QuickSearch';
import TransactionsList from './TransactionsList';
import { MainNavLink, MainNavButton } from './MainNavButton';
import TasksDrawer from '../Tasks/Drawer';
import NewTransaction from '@common/NewTransaction';
import { useMyTasks } from '../resources/tasks';
import { useTransactionsListData } from './useMainNavData';

function useTaskCompletionMessage(tasks, currentUser) {
  const toast = useToast();
  const previousTasksSize = useRef(tasks.remainingToday);
  useEffect(() => {
    if (previousTasksSize.current > 0 && tasks.remainingToday === 0) {
      toast({
        title: 'All tasks due today are done!',
        description: `Great job, ${
          currentUser.displayName.split(' ')[0]
        }. Go have a 🍷`,
        status: 'success',
        isClosable: true,
      });
    }
    return () => {
      previousTasksSize.current = tasks.remainingToday;
    };
  }, [tasks.remainingToday, toast, currentUser.displayName]);
}

function Tasks({ tasks }) {
  const tasksDrawerDisclosure = useDisclosure();

  if (tasks.error) {
    console.error(tasks.error);
    Sentry.captureException(tasks.error);
  }

  return (
    <Box position="relative">
      <IconButton
        variant="outline"
        aria-label="My Tasks"
        disabled={tasks.isLoading || tasks.error}
        onClick={tasksDrawerDisclosure.onToggle}
        icon={
          tasks.isLoading ? (
            <Spinner w={5} h={5} />
          ) : tasks.error ? (
            <WarningIcon w={5} h={5} color="red.500" />
          ) : (
            <Icon as={VscInbox} w={5} h={5} />
          )
        }
      />
      {!tasks.isLoading && tasks.remainingToday > 0 && (
        <Circle
          w={5}
          h={5}
          fontSize="xs"
          bg="red.400"
          color="white"
          position="absolute"
          top={0}
          right={0}
          transform="translate(8px, -8px);"
        >
          {tasks.remainingToday}
        </Circle>
      )}
      <TasksDrawer {...tasksDrawerDisclosure} tasks={tasks} />
    </Box>
  );
}

Tasks.propTypes = {
  currentUser: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }),
  tasks: PropTypes.shape({
    error: PropTypes.any,
    isLoading: PropTypes.bool,
    remainingToday: PropTypes.number,
  }),
};

function MainNav({ toggle, agents, tasks, isLoading, error, currentUser }) {
  const quickSearchDialog = useDisclosure();
  const newTransactionDialog = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  useKeyboardShortcut('Control', '\\', quickSearchDialog.onToggle);
  return (
    // TODO: make this resizable
    <>
      <PanelHeader
        leftActions={
          <>
            <Box minW="40px" py={2}>
              <RouterLink to="/">
                <img
                  style={{ height: '40px' }}
                  src="/logo-mark.png"
                  alt="Closing Bot"
                />
              </RouterLink>
            </Box>
            <Box>
              <Menu autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  ml={1}
                  variant="ghost"
                  cursor="pointer"
                  size="sm"
                  icon={
                    <>
                      <Avatar size="sm" src={currentUser.photoURL} />
                      <ChevronDownIcon />
                    </>
                  }
                />
                <MenuList>
                  <MenuItem
                    icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    onClick={toggleColorMode}
                  >
                    {colorMode === 'light' ? 'Dark' : 'Light'} Mode
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={logout}>Log out</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </>
        }
        actions={
          <>
            <Tasks tasks={tasks} currentUser={currentUser} />
            {toggle}
          </>
        }
      />
      <PanelBody p={2}>
        <Stack spacing={2} divider={<Divider />}>
          <Stack spacing={1}>
            <MainNavLink icon={<Icon as={VscFolder} />} to="/dashboard">
              Dashboard
            </MainNavLink>
            <MainNavButton
              icon={<SearchIcon />}
              onClick={quickSearchDialog.onOpen}
            >
              <Tooltip label={<kbd>Ctrl + \</kbd>}>Search</Tooltip>
            </MainNavButton>
            <MainNavLink icon={<Icon as={VscChecklist} />} to="/tasks">
              Tasks
            </MainNavLink>
            <MainNavLink
              icon={<Icon as={VscAccount} />}
              to="/agents"
              isActive={(match, { pathname }) =>
                match && !pathname.includes('/transactions/')
              }
            >
              Agents
            </MainNavLink>
          </Stack>
          <MainNavButton
            icon={<AddIcon />}
            onClick={newTransactionDialog.onOpen}
          >
            New Transaction
          </MainNavButton>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : error ? (
            error
          ) : (
            <TransactionsList agents={agents} />
          )}
        </Stack>
      </PanelBody>
      <QuickSearch {...quickSearchDialog} />
      {newTransactionDialog.isOpen && (
        <NewTransaction {...newTransactionDialog} />
      )}
    </>
  );
}

MainNav.propTypes = {
  agents: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  tasks: PropTypes.object.isRequired,
  toggle: PropTypes.node.isRequired,
};

function MainNavContainer() {
  const [
    agentsWithActiveTransactions,
    isLoading,
    error,
  ] = useTransactionsListData();
  const [currentUser] = useCurrentUser();
  const tasks = useMyTasks(currentUser);
  useTaskCompletionMessage(tasks, currentUser);

  const drawerState = useDisclosure();
  const dockedState = useDisclosure({ defaultIsOpen: true });
  return (
    <>
      <Panel
        borderRightWidth="1px"
        maxW="260px"
        minW="260px"
        display={dockedState.isOpen ? ['none', null, 'inherit'] : 'none'}
      >
        <MainNav
          isDocked={dockedState.isOpen}
          onDockToggle={dockedState.onToggle}
          isLoading={isLoading}
          error={error}
          agents={agentsWithActiveTransactions}
          tasks={tasks}
          currentUser={currentUser}
          toggle={
            <IconButton
              size="xs"
              icon={<ArrowLeftIcon />}
              aria-label="Collapse the navigation"
              variant="outline"
              onClick={dockedState.onClose}
            />
          }
        />
      </Panel>

      <IconButton
        position="fixed"
        bottom={2}
        left={2}
        colorScheme="teal"
        icon={<HamburgerIcon />}
        onClick={drawerState.onOpen}
        zIndex="sticky"
        boxShadow="dark-lg"
        size="lg"
        display={['inherit', null, dockedState.isOpen ? 'none' : 'inherit']}
      />
      <Drawer
        size="xs"
        onClose={drawerState.onClose}
        isOpen={drawerState.isOpen}
        placement="left"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerBody p={0}>
              <Box px={2}>
                <MainNav
                  currentUser={currentUser}
                  tasks={tasks}
                  agents={agentsWithActiveTransactions}
                  isLoading={isLoading}
                  error={error}
                  toggle={
                    <>
                      <IconButton
                        size="xs"
                        display={['none', null, 'inherit']}
                        icon={<LockIcon />}
                        aria-label="dock the navigation"
                        variant="outline"
                        onClick={() => {
                          drawerState.onClose();
                          dockedState.onOpen();
                        }}
                      />
                      <IconButton
                        size="xs"
                        icon={<ArrowLeftIcon />}
                        aria-label="Collapse the navigation"
                        variant="outline"
                        onClick={drawerState.onClose}
                      />
                    </>
                  }
                />
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default MainNavContainer;

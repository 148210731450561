import PropTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';
import Section from '@common/Section';

function Fieldset({ children, legend, ...props }) {
  return (
    <Section title={legend}>
      <Stack p={4} spacing={2} {...props}>
        {children}
      </Stack>
    </Section>
  );
}

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.node.isRequired,
};

export default Fieldset;

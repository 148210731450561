import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack,
  Input,
  Select,
  Button,
  HStack,
  Checkbox,
  useToast,
  ButtonGroup,
  VisuallyHidden,
} from '@chakra-ui/react';
import Autocomplete from '@common/Autocomplete';
import useDraftDocument from '@common/useDraftDocument';
import {
  transactionsCollection,
  useTransactions,
} from '../resources/transactions';
import {
  createTask,
  tasksCollection,
  getInitialTaskState,
} from '../resources/tasks';
import { useCollaboratorsList } from '../resources/collaborators';
import DateInput, { getTransactionTimeline } from '@common/DateInput';

function NewTaskForm({ onClose, initialValues = {} }) {
  const [isSaving, setIsSaving] = useState();
  const [draftTask, setDraftTask] = useDraftDocument(
    getInitialTaskState(initialValues)
  );
  const [activeTransctions, isTransactionsLoading, error] = useTransactions(
    transactionsCollection.where('paymentStatus', '==', 'PENDING')
  );
  const toast = useToast();
  const collaborators = useCollaboratorsList();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSaving(true);
    try {
      await createTask(tasksCollection, draftTask);
      toast({
        status: 'success',
        title: 'Task created.',
        isClosable: true,
      });
    } catch (err) {
      toast({
        status: 'error',
        title: 'An error occurred.',
        description: 'Unable to create task.' + err,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  if (error) {
    throw new Error(`unable to fetch transactions. ${error}`);
  }

  const options =
    activeTransctions && activeTransctions.docs
      ? activeTransctions.docs.map((transaction) => {
          return {
            value: transaction,
            label: transaction.data().name,
          };
        })
      : [];
  const selectedTransaction =
    draftTask.parent &&
    options.find((o) => o.value.ref.path === draftTask.parent.path);
  let baseDates = {};
  if (selectedTransaction) {
    baseDates = getTransactionTimeline(selectedTransaction.value.data());
  }

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalHeader>New task</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={8}>
            <FormControl>
              <FormLabel>
                <VisuallyHidden>Text</VisuallyHidden>
              </FormLabel>
              <Input
                autoFocus
                variant="flushed"
                value={draftTask.text}
                onChange={(event) => {
                  setDraftTask({
                    text: event.target.value,
                  });
                }}
                placeholder="Enter a new task..."
              />
            </FormControl>
            <HStack>
              <FormControl>
                <FormLabel>Transaction</FormLabel>
                <Autocomplete
                  isLoading={isTransactionsLoading}
                  placeholder="Select a transaction"
                  options={options}
                  value={selectedTransaction}
                  onChange={(selected) => {
                    setDraftTask({
                      parent: selected.value.ref,
                      parentDisplayName: selected.label,
                    });
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Assign to</FormLabel>
                <Select
                  value={draftTask.assignee || ''}
                  onChange={(event) => {
                    setDraftTask({ assignee: event.target.value });
                  }}
                >
                  <option key="unassigned" value="">
                    Unassigned
                  </option>
                  {collaborators.map((collaborator) => (
                    <option key={collaborator.uid} value={collaborator.uid}>
                      {collaborator.displayName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>
            <HStack spacing={6}>
              <DateInput
                label="When"
                size="md"
                variant="outline"
                value={draftTask.startDate}
                baseDates={baseDates}
                onChange={(startDate) => {
                  setDraftTask({
                    startDate,
                  });
                }}
              />
              <Checkbox
                isChecked={draftTask.isUrgent}
                onChange={(event) => {
                  setDraftTask({ isUrgent: event.target.checked });
                }}
              >
                Urgent
              </Checkbox>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              isLoading={isSaving}
              colorScheme="teal"
              disabled={draftTask.text === ''}
              type="submit"
            >
              Save task
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </form>
  );
}

NewTaskForm.propTypes = {
  initialValues: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

function NewTask({ onClose, isOpen, initialValues }) {
  return (
    <Modal
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="xl"
    >
      <ModalOverlay />
      <NewTaskForm initialValues={initialValues} onClose={onClose} />
    </Modal>
  );
}

NewTask.propTypes = {
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewTask;

import PropTypes from 'prop-types';
import {
  Heading,
  HStack,
  Flex,
  Text,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react';
import { CheckIcon, CalendarIcon } from '@chakra-ui/icons';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import { format, isSameDay } from 'date-fns';
import getTransactionTimeline from '@common/getTransactionTimeline';
import { useTasks, tasksCollection } from '../../resources/tasks';
import { TITLES } from '../../constants/timelineDates';
import { Shortcuts } from './Shortcuts';
import { normalizeTimezone } from '@common/utils/date';

function getEvents(transactionData, tasks) {
  const unsorted = [];

  Object.entries(getTransactionTimeline(transactionData)).forEach(
    ([key, date]) => {
      unsorted.push({
        key,
        type: 'transaction',
        date: date,
        label: TITLES[key],
      });
    }
  );

  tasks.forEach((task) => {
    if (task.data().startDate) {
      unsorted.push({
        key: task.id,
        type: 'task',
        label: task.data().text,
        date: normalizeTimezone(task.data().startDate.toDate()),
      });
    }
  });
  const timeline = unsorted.sort((a, b) => a.date - b.date);

  return timeline;
}

function Timeline({ transaction }) {
  const [tasks, isLoading, error] = useTasks(
    tasksCollection
      .where('parent', '==', transaction.ref)
      .orderBy('startDate', 'asc')
      .orderBy('createdAt', 'desc')
  );

  if (error) {
    throw new Error('Unable to load transaction tasks' + error);
  }
  if (isLoading || !tasks.docs || !transaction.exists) {
    return null;
  }

  const events = getEvents(transaction.data(), tasks.docs);
  if (events.length === 0) {
    return null;
  }

  const start = events[0].date;
  const end = events[events.length - 1].date;
  const interval = eachDayOfInterval({ start, end });
  return (
    <List spacing={8}>
      {interval.map((date) => {
        const eventsOnThisDay = events.filter((event) =>
          isSameDay(event.date, date)
        );
        if (eventsOnThisDay.length === 0) {
          return null;
        }

        return (
          <ListItem key={date.toString()}>
            <HStack as="header">
              <Heading
                as="h4"
                size="xs"
                textStyle="secondary"
                textTransform="uppercase"
              >
                {format(date, 'E M/d')}
              </Heading>
            </HStack>
            <List mt={2} spacing={1}>
              {eventsOnThisDay.map((timelineEvent) => {
                return (
                  <ListItem fontSize="sm" key={timelineEvent.key}>
                    <Flex alignItems="center">
                      <ListIcon
                        color="teal.500"
                        as={
                          timelineEvent.type === 'task'
                            ? CheckIcon
                            : CalendarIcon
                        }
                      />
                      <Text isTruncated>{timelineEvent.label}</Text>
                    </Flex>
                    {timelineEvent.type === 'transaction' && (
                      <Shortcuts
                        shortcutKey={timelineEvent.key}
                        transaction={transaction}
                        date={date}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </ListItem>
        );
      })}
    </List>
  );
}

Timeline.propTypes = {
  transaction: PropTypes.shape({
    data: PropTypes.func,
    exists: PropTypes.any,
    ref: PropTypes.any,
  }),
};

export default Timeline;

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Badge, Spacer, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import LinkButton from '@common/LinkButton';

export const MainNavButton = React.forwardRef(function MainNavButton(
  { count, icon, children, ...props },
  ref
) {
  return (
    <Button ref={ref} leftIcon={icon} variant="mainNav" size="sm" {...props}>
      <Text isTruncated>{children}</Text>
      {count && (
        <>
          <Spacer />
          <Badge borderRadius="lg" variant="solid">
            {count}
          </Badge>
        </>
      )}
    </Button>
  );
});

MainNavButton.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  icon: PropTypes.node.isRequired,
};

const useScrollToCurrentPage = (ref) => {
  useEffect(() => {
    if (ref.current.getAttribute('aria-current')) {
      ref.current.scrollIntoViewIfNeeded();
    }
  }, [ref]);
};

export function MainNavLink({ count, icon, children, ...props }) {
  const ref = useRef();
  useScrollToCurrentPage(ref);

  return (
    <NavLink
      ref={ref}
      component={LinkButton}
      leftIcon={icon}
      variant="mainNav"
      size="sm"
      {...props}
    >
      <Text isTruncated>{children}</Text>
      {count && (
        <>
          <Spacer />
          <Badge borderRadius="lg">{count}</Badge>
        </>
      )}
    </NavLink>
  );
}

MainNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  icon: PropTypes.node.isRequired,
};

import PropTypes from 'prop-types';
import { Box, Flex, Text, Divider } from '@chakra-ui/react';

const Section = ({
  children,
  title,
  action = null,
  progress = null,
  ...props
}) => {
  return (
    <Box layerStyle="section" borderWidth="1px" {...props}>
      {title && (
        <>
          <Flex align="center" justify="space-between" p={4}>
            <Text as="h3" fontWeight="bold" fontSize="md">
              {title}
            </Text>
            {action}
          </Flex>
          {progress}
          <Divider />
        </>
      )}
      {children}
    </Box>
  );
};

Section.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  progress: PropTypes.node,
  title: PropTypes.node,
};

export default Section;

import {
  useDocument,
  useCollection,
  useCollectionOnce,
} from 'react-firebase-hooks/firestore';
import { TODAY, TOMORROW } from '@common/utils/date';
import { firestore, Timestamp } from './firebase';

export const tasksCollection = firestore.collection('tasks');

export function useTasks(collection) {
  const [snapshots = {}, isLoading, error] = useCollection(collection, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return [snapshots, isLoading, error];
}

export function useTasksOnce(collection) {
  const [snapshots = {}, isLoading, error] = useCollectionOnce(collection, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return [snapshots, isLoading, error];
}

export function useMyTasks(currentUser) {
  const [today = {}, isLoadingToday, errorToday] = useTasks(
    tasksCollection
      .where('isArchived', '==', false)
      .where('assignee', '==', currentUser.uid)
      .where('startDate', '<=', TODAY)
  );

  const [upcoming = {}, isLoadingUpcoming, errorUpcoming] = useTasks(
    tasksCollection
      .where('isArchived', '==', false)
      .where('assignee', '==', currentUser.uid)
      .where('startDate', '!=', null)
      .orderBy('startDate', 'asc')
      .startAt(TOMORROW)
      .limit(25)
  );

  const [unscheduled = {}, isLoadingUnscheduled, errorUnscheduled] = useTasks(
    tasksCollection
      .where('isArchived', '==', false)
      .where('assignee', '==', currentUser.uid)
      .where('startDate', '==', null)
  );

  const remainingToday =
    today && today.docs
      ? today.docs.filter((task) => !task.data().isComplete).length
      : null;

  return {
    today,
    upcoming,
    unscheduled,
    remainingToday,
    isLoading: isLoadingToday || isLoadingUpcoming || isLoadingUnscheduled,
    error: errorToday || errorUpcoming || errorUnscheduled,
  };
}

export function useMyTasksOnce(currentUser) {
  const [today = {}, isLoadingToday, errorToday] = useTasksOnce(
    tasksCollection
      .where('assignee', '==', currentUser.uid)
      .where('startDate', '<=', TODAY)
  );
  const [upcoming = {}, isLoadingUpcoming, errorUpcoming] = useTasksOnce(
    tasksCollection
      .where('isArchived', '==', false)
      .where('assignee', '==', currentUser.uid)
      .where('startDate', '!=', null)
      .orderBy('startDate', 'asc')
      .startAt(TOMORROW)
      .limit(25)
  );

  const [
    unscheduled = {},
    isLoadingUnscheduled,
    errorUnscheduled,
  ] = useTasksOnce(
    tasksCollection
      .where('isArchived', '==', false)
      .where('assignee', '==', currentUser.uid)
      .where('startDate', '==', null)
  );

  const remainingToday =
    today && today.docs
      ? today.docs.filter((task) => !task.data().isComplete).length
      : null;

  return {
    today,
    upcoming,
    unscheduled,
    remainingToday,
    isLoading: isLoadingToday || isLoadingUpcoming || isLoadingUnscheduled,
    error: errorToday || errorUpcoming || errorUnscheduled,
  };
}

export function useTask(path) {
  const ref = firestore.doc(path);
  return useDocument(ref);
}

export function createTask(collection, data) {
  if (data.startDate) {
    data.startDate = Timestamp.fromDate(data.startDate);
  }

  return collection.add({
    ...getInitialTaskState(),
    ...data,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  });
}

export async function updateTask(task, data) {
  if (data.startDate) {
    data.startDate = Timestamp.fromDate(data.startDate);
  }

  if (data.isComplete === false) {
    data.isArchived = false;
  }

  await task.ref.update({
    ...data,
    completedDate: data.isComplete ? Timestamp.now() : null,
    updatedAt: Timestamp.now(),
  });
  return task;
}

export function deleteTask(task) {
  return task.ref.delete();
}

export function deleteTasks(tasks) {
  const batch = firestore.batch();
  tasks.forEach((doc) => batch.delete(doc.ref));
  return batch.commit();
}

export function completeTasks(tasks) {
  const batch = firestore.batch();
  tasks.forEach((doc) => batch.update(doc.ref, { isComplete: true }));
  return batch.commit();
}

export function archiveTasks(tasks) {
  const batch = firestore.batch();
  tasks.forEach((doc) => batch.update(doc.ref, { isArchived: true }));
  return batch.commit();
}

export function getInitialTaskState(overrides = {}) {
  return {
    text: '',
    parent: null,
    parentDisplayName: null,
    assignee: null,
    isUrgent: false,
    isWaiting: false,
    isComplete: false,
    isArchived: false,
    startDate: null,
    completedDate: null,
    ...overrides,
  };
}

import { useEffect, useState } from 'react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import {
  CONTRACT_STATUSES,
  PAYMENT_STATUSES,
} from '../constants/transactionStatuses';
import {
  DEFAULT_CONTRACT_FEE,
  DEFAULT_TERMINATION_FEE,
} from '../constants/fees';
import { DEFAULT_CHECKLISTS } from '../constants/checklists';
import firebase, { Timestamp, functions, firestore } from './firebase';
import { normalizeTimezone, TODAY } from '@common/utils/date';

export const transactionsCollection = firestore.collection('transactions');

export const getInitialTransactionState = () => ({
  name: '',
  shortName: '',
  fee: DEFAULT_CONTRACT_FEE,
  terminationFee: DEFAULT_TERMINATION_FEE,
  status: CONTRACT_STATUSES.ACTIVE,
  paymentStatus: PAYMENT_STATUSES.PENDING,
  isRepresentingSeller: false,
  isRepresentingBuyer: false,
  checklistsMap: {},
  checklists: DEFAULT_CHECKLISTS,
  bindingDate: TODAY,
  createdAt: Timestamp.now(),
  updatedAt: Timestamp.now(),
});

export function useTransactions(collection) {
  return useCollection(collection);
}

export function useTransaction(path) {
  const ref = typeof path === 'string' ? firestore.doc(path) : path;
  const [doc, isLoading, error] = useDocument(ref);
  if (error) {
    throw new Error('transaction query failed: ' + error);
  }

  if (isLoading) {
    return [ref, isLoading, error];
  }

  return [doc, isLoading, error];
}

export function createTransaction(data) {
  return transactionsCollection.add(data);
}

function getChangelogEntry(data) {
  return Object.entries(data)
    .filter(([key, val]) => {
      return key !== 'updatedAt';
    })
    .map(([key, value]) => {
      if (value && value.toDate) {
        return `set ${key} to ${normalizeTimezone(
          value.toDate()
        ).toDateString()}`;
      }

      if (key === 'exhibits') {
        return `set ${key} to ${value.join(', ')}`;
      }

      return ` set ${key} to ${value}`;
    })
    .join('\n');
}

export function useTransactionMutation(document) {
  const [mutating, setMutating] = useState(false);
  const [error, setError] = useState();

  const updateDoc = (data) => {
    setMutating(true);

    if (data.status === CONTRACT_STATUSES.ACTIVE) {
      data.closedOrTerminatedAt = null;
    } else if (data.status === CONTRACT_STATUSES.CLOSED) {
      data.closedOrTerminatedAt = Timestamp.now();
    } else if (data.status === CONTRACT_STATUSES.TERMINATED) {
      data.closedOrTerminatedAt = Timestamp.now();
    }
    data.updatedAt = Timestamp.now();

    if (data.hasOwnProperty('name') && data.name.length === 0) {
      data.name = 'Untitled';
    }

    return document.ref
      .update(data)
      .then(() => {
        return document.ref.collection('history').add({
          userId: firebase.auth().currentUser.uid,
          change: getChangelogEntry(data),
          when: data.updatedAt,
        });
      })
      .catch((e) => setError(e))
      .finally(() => setMutating(false));
  };

  return [updateDoc, mutating, error];
}

export function deleteTransaction(transaction) {
  return transaction.ref.delete().catch((error) => {
    throw new Error('transaction delete failed: ' + error);
  });
}

export async function cloneTransaction(transaction, agent) {
  const data = transaction.data();
  const cloneableProperties = [
    'name',
    'address',
    'county',
    'fmls',
    'coopAgent',
    'additionalContact',
    'lender',
    'closingAttorney',
    'precloser',
    'seller',
    'buyer',
    'isRepresentingBuyer',
    'isRepresentingSeller',
    'fee',
  ];

  const clonedTransactionData = cloneableProperties.reduce((memo, key) => {
    if (key === 'name') {
      memo[key] = data[key] + ' (Cloned)';
    } else if (data[key]) {
      memo[key] = data[key];
    }

    return memo;
  }, getInitialTransactionState());
  clonedTransactionData.agent = agent.ref;
  clonedTransactionData.fee = agent.data().fee || DEFAULT_CONTRACT_FEE;
  clonedTransactionData.terminationFee =
    agent.data().terminationFee || DEFAULT_TERMINATION_FEE;
  return transactionsCollection.add(clonedTransactionData);
}

export function useTransactionHistory(ref) {
  const [doc, isLoading, error] = useDocument(ref);
  if (error) {
    throw new Error('transaction query failed: ' + error);
  }

  return [doc, isLoading];
}

export function useTransactionStats() {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    functions
      .httpsCallable('getTransactionStats')()
      .then((result) => {
        setIsLoading(false);
        setValue(result.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, []);

  return [value, isLoading, error];
}

import { useRef, useEffect } from 'react';

function useKeyboardShortcut(MOD_KEY, ACTION_KEY, callback) {
  const MOD_KEY_PRESSED = useRef(false);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (
        event.target.tagName === 'INPUT' ||
        event.target.tagName === 'TEXTAREA'
      ) {
        return;
      }

      if (event.key === MOD_KEY) {
        MOD_KEY_PRESSED.current = true;
      }
    };

    const handleKeyup = (event) => {
      if (
        event.target.tagName === 'INPUT' ||
        event.target.tagName === 'TEXTAREA'
      ) {
        return;
      }

      if (event.key === MOD_KEY) {
        MOD_KEY_PRESSED.current = false;
      }

      if (MOD_KEY_PRESSED.current && event.key === ACTION_KEY) {
        callback();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  }, [ACTION_KEY, MOD_KEY, callback]);
}

export default useKeyboardShortcut;

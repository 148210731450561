import PropTypes from 'prop-types';
import { Fieldset } from '@common/FormField';

import { Duration, Select } from '../View/Fields';

function Financing({ transaction }) {
  return (
    <Fieldset
      legend="Financing & Appraisal"
      sx={{
        '--form-label-width': '160px',
      }}
    >
      <Select
        label="Financing Type"
        name="financingType"
        document={transaction}
      >
        <option value="Conventional">Conventional</option>
        <option value="FHA">FHA</option>
        <option value="Cash">Cash</option>
        <option value="VA">VA</option>
      </Select>
      <Duration
        label="Financing Contigency"
        name="financingDuration"
        document={transaction}
      />
      <Duration
        label="Appraisal Contingency"
        name="appraisalDuration"
        document={transaction}
      />
    </Fieldset>
  );
}

Financing.propTypes = {
  transaction: PropTypes.object,
};

export default Financing;

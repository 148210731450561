export const DEFAULT_CHECKLISTS = [
  {
    title: 'New Contract',
    items: [
      'Fully signed contract',
      'Binding filled-in',
      'Legal description',
      'Pre-qual letter',
      'SPD',
      'CAD',
      'Lead based paint',
      'Dekalb water',
      'Buyer brokerage agreement',
      'Listing agreement',
      'ABD',
      'Email to client',
      'Email to co-op',
      'Email to lender',
      'Email to closing attorney',
      'Add dates to pending calendar',
      'Change status in MLS',
    ],
  },

  {
    title: 'Pre Due Diligence',
    items: [
      'EM email to buyer',
      'Send EM to co-op, lender, closing attorney',
      'Upload all docs to system',
      'Upload EM to system',
      'Ask for/send CBS',
      'Schedule inspection',
      'Send CBS to inspector',
      'Create/request commission agreement',
      'Commission agreement to Dotloop & attorney',
      'Request/send utility form',
      'Remind of DD ending 3 days in advance',
      'Confirm buyer/seller docs sent to Attorney',
      'Add Transaction to Spreadsheet',
      'Create File on Google Drive',
    ],
  },
  {
    title: 'Pre Closing',
    items: [
      'Set up closing time',
      'Add closing time to pending calendar',
      'Remind of FC/AC ending 3 days in advance',
      'Order home warranty',
      'Request/order termite letter',
      'Send warranty & termite letter to attorney & lender',
      'HOA Clearance Letter to Attorney',
      'Complete Greensheet',
      'Complete PAC',
      'Send PAC to attorney',
      'Request/send repair receipts',
      'Check special stips',
      'Request preliminary HUD',
      'Send preliminary HUD',
      'Check w/ lender for CD',
      'Send closing email to client 3 days in advance',
      'Schedule walk through',
    ],
  },
  {
    title: 'Post Closing',
    items: [
      'Send congrats email with Zillow link, request new mailing address',
      'Close on GAMLS',
      'Prepare & upload 118',
      'Close on system',
      'Scan all docs into file',
    ],
  },
];

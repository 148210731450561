import PropTypes from 'prop-types';
import { Fieldset } from '@common/FormField';
import { Text, Duration, Date } from './Fields';

function Dates({ transaction }) {
  return (
    <Fieldset
      legend="Dates"
      sx={{
        '--form-label-width': '100px',
      }}
    >
      <Date label="Binding Date" name="bindingDate" document={transaction} />
      <Date label="Offer Date" name="offerDate" document={transaction} />
      <Duration
        label="Due Diligence"
        name="dueDiligenceDuration"
        document={transaction}
      />
      <Date label="Closing Date" name="closingDate" document={transaction} />
      <Text
        label="Closing Time"
        name="closingTime"
        document={transaction}
        maxW="120px"
      />
    </Fieldset>
  );
}

Dates.propTypes = {
  transaction: PropTypes.shape({
    data: PropTypes.func,
  }),
};

export default Dates;

import PropTypes from 'prop-types';
import { Box, useDisclosure, Collapse, Stack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Status } from '@common/TransactionStatus';
import { MainNavButton, MainNavLink } from './MainNavButton';

const TransactionsListGroup = ({ count, title, children }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  return (
    <Box>
      <MainNavButton
        count={count}
        onClick={onToggle}
        fontWeight="semibold"
        icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
      >
        {title}
      </MainNavButton>
      <Collapse in={isOpen}>
        <Stack p={1} ml={3} spacing={1}>
          {children}
        </Stack>
      </Collapse>
    </Box>
  );
};

TransactionsListGroup.propTypes = {
  count: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

function TransactionsList({ agents }) {
  return agents.map((agent) => (
    <TransactionsListGroup
      key={agent.id}
      title={agent.name}
      count={agent.activeTransactions.length}
    >
      {agent.activeTransactions.map((transaction) => {
        const to = `/transactions/${transaction.id}`;
        return (
          <MainNavLink
            key={transaction.id}
            fontWeight="normal"
            icon={<Status variant="bullet" type={transaction.data().status} />}
            to={to}
          >
            {transaction.data().name}
          </MainNavLink>
        );
      })}
    </TransactionsListGroup>
  ));
}

TransactionsList.propTypes = {
  agents: PropTypes.array,
};

export default TransactionsList;

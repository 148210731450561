import PropTypes from 'prop-types';
import { Flex, Box, Text, FormControl, FormLabel } from '@chakra-ui/react';

function FormField({ label, children, ...props }) {
  return (
    <Flex as={FormControl} alignItems="flex-start" {...props}>
      <FormLabel minW="var(--form-label-width);" mt={1}>
        <Text fontSize="sm" isTruncated>
          {label}
        </Text>
      </FormLabel>
      <Box flex={1} position="relative">
        {children}
      </Box>
    </Flex>
  );
}

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  minLabelWidth: PropTypes.number,
};

export default FormField;

import PropTypes from 'prop-types';
import { useTransaction } from '../../resources/transactions';
import ErrorBoundary from '@common/ErrorBoundary';
import TransactionView from './View';

function TransactionsViewContainer({ match }) {
  const docPath = `/transactions/${match.params.id}`;
  const [transaction, isLoading, error] = useTransaction(docPath);

  if (error) {
    throw new Error('Unable to load transaction. ' + error);
  }

  return (
    <TransactionView
      docPath={docPath}
      transaction={transaction}
      isLoading={isLoading}
    />
  );
}

TransactionsViewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      agentId: PropTypes.any,
      id: PropTypes.any,
    }),
  }).isRequired,
};

function TransactionViewContainerWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <TransactionsViewContainer {...props} />
    </ErrorBoundary>
  );
}

export default TransactionViewContainerWithErrorBoundary;

import {
  calculateDateFromDuration,
  normalizeTimezone,
} from '@common/utils/date';

export default function getTransactionTimeline(transactionData) {
  const dates = {};
  if (transactionData.bindingDate) {
    dates.bindingDate = normalizeTimezone(transactionData.bindingDate.toDate());
    if (transactionData.dueDiligenceDuration) {
      dates.dueDiligenceDuration = calculateDateFromDuration(
        transactionData.bindingDate,
        transactionData.dueDiligenceDuration
      );
    }

    if (transactionData.emDuration) {
      dates.emDuration = calculateDateFromDuration(
        transactionData.bindingDate,
        transactionData.emDuration
      );
    }

    if (transactionData.financingDuration) {
      dates.financingDuration = calculateDateFromDuration(
        transactionData.bindingDate,
        transactionData.financingDuration
      );
    }

    if (transactionData.appraisalDuration) {
      dates.appraisalDuration = calculateDateFromDuration(
        transactionData.bindingDate,
        transactionData.appraisalDuration
      );
    }
  }

  if (transactionData.closingDate) {
    dates.closingDate = normalizeTimezone(transactionData.closingDate.toDate());
  }
  return dates;
}

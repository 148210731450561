import PropTypes from 'prop-types';
import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { VscBookmark } from 'react-icons/vsc';
import addDays from 'date-fns/addDays';
import {
  useCollaboratorsList,
  getUidByDisplayName,
} from '../../resources/collaborators';
import NewTask from '../../Tasks/NewTask';
import { TIMELINE, COLORS } from '../../constants/timelineDates';

const SHORTCUTS = {
  [TIMELINE.CLOSING]: [
    {
      colorScheme: COLORS[TIMELINE.CLOSING],
      label: 'Closing email',
      task: ({ date, collaborators }) => {
        return {
          text: 'Send closing email',
          assignee: getUidByDisplayName(collaborators, 'Anne'),
          startDate: addDays(date, -5),
        };
      },
    },
    {
      colorScheme: COLORS[TIMELINE.CLOSING],
      label: 'Compliance review',
      task: ({ date, collaborators }) => {
        return {
          text: 'Submit for compliance review',
          assignee: getUidByDisplayName(collaborators, 'Anne'),
          startDate: addDays(date, -5),
        };
      },
    },
  ],
  [TIMELINE.DD]: [
    {
      colorScheme: COLORS[TIMELINE.DD],
      label: 'Remind DD',
      task: ({ date, collaborators }) => {
        return {
          text: 'Remind DD',
          assignee: getUidByDisplayName(collaborators, 'Amanda'),
          startDate: addDays(date, -4),
        };
      },
    },
    {
      colorScheme: COLORS[TIMELINE.DD],
      label: 'Utility Info',
      task: ({ date, collaborators }) => {
        return {
          text: 'Request and share utility info',
          assignee: getUidByDisplayName(collaborators, 'Anne'),
          startDate: addDays(date, 3),
        };
      },
    },
    {
      colorScheme: COLORS[TIMELINE.DD],
      label: 'Commission agreement',
      task: ({ date, collaborators }) => {
        return {
          text: 'Create or request commission agreement',
          assignee: getUidByDisplayName(collaborators, 'Anne'),
          startDate: addDays(date, 1),
        };
      },
    },
  ],
  [TIMELINE.FINANCING]: [
    {
      colorScheme: COLORS[TIMELINE.FINANCING],
      label: 'Remind FC',
      task: ({ date, collaborators }) => {
        return {
          text: 'Remind FC',
          assignee: getUidByDisplayName(collaborators, 'Amanda'),
          startDate: addDays(date, -4),
        };
      },
    },
  ],
  [TIMELINE.APPRAISAL]: [
    {
      colorScheme: COLORS[TIMELINE.APPRAISAL],
      label: 'Remind AC',
      task: ({ date, collaborators }) => {
        return {
          text: 'Remind AC',
          assignee: getUidByDisplayName(collaborators, 'Amanda'),
          startDate: addDays(date, -4),
        };
      },
    },
  ],
};

export function Shortcut({ label, task, transaction, date, ...props }) {
  const newTaskDisclosure = useDisclosure();
  const collaborators = useCollaboratorsList();
  const taskValues = {
    ...task({ date, transaction, collaborators }),
    parent: transaction.ref,
    parentDisplayName: transaction.data().name,
  };
  return (
    <>
      <Button
        leftIcon={<VscBookmark />}
        layerStyle="selected"
        textAlign="left"
        display="flex"
        onClick={newTaskDisclosure.onOpen}
        {...props}
      >
        {props.children || label}
      </Button>
      {newTaskDisclosure.isOpen && (
        <NewTask {...newTaskDisclosure} initialValues={taskValues} />
      )}
    </>
  );
}

Shortcut.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  label: PropTypes.node,
  task: PropTypes.func,
  transaction: PropTypes.shape({
    data: PropTypes.func,
    ref: PropTypes.any,
  }),
};

export function Shortcuts({ shortcutKey, date, transaction }) {
  if (!SHORTCUTS[shortcutKey]) {
    return null;
  }

  return (
    <>
      <ButtonGroup size="xs" variant="outline" ml={2} flexWrap="wrap">
        {SHORTCUTS[shortcutKey].map((shortcut) => (
          <Shortcut
            {...shortcut}
            key={shortcut.label}
            date={date}
            transaction={transaction}
          />
        ))}
      </ButtonGroup>
    </>
  );
}

Shortcuts.propTypes = {
  date: PropTypes.instanceOf(Date),
  shortcutKey: PropTypes.string.isRequired,
  transaction: PropTypes.object.isRequired,
};

import firebase from 'firebase/app';
// eslint-disable-next-line import/no-unassigned-import
import 'firebase/auth';
// eslint-disable-next-line import/no-unassigned-import
import 'firebase/functions';
// eslint-disable-next-line import/no-unassigned-import
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

export const authenticateWithGoogle = () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(googleProvider);
};

export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const { Timestamp, FieldValue } = firebase.firestore;

export default firebase;

if (window.location.hostname === 'localhost') {
  functions.useEmulator('localhost', 5001);
  firestore.useEmulator('localhost', 8585);
}
